import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { OperationUnitService } from '../../operational-unit/service/operation-unit.service'

@Injectable({
  providedIn: 'root'
})

export class EnterpriseService {
  private baseUrl: string = "";

  constructor(
    private http: HttpClient
  ) {
    this.baseUrl = environment.api;
  }

  private setHeader(withCredentials: boolean) {
    return {
      headers: new HttpHeaders({
        'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
        'Cache-Control': 'no-cache',
        'accept': '*/*',
        'Content-Type': 'application/json'
      }),
    };
  }

  operationalUnitsUUID(id): Observable<any> {
    return this.http.get(`${this.baseUrl}operational-units/${id}`, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro:', error);
          return of([]);
        })
      );
  }

  listUserCompany(): Observable<any> {

    return this.http.get(`${this.baseUrl}user/all-users-company?page=0&size=50&companyId=${localStorage.getItem('companyId')}`, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro ao listar usuarios:', error);
          return of([]);
        })
      );

  }

  addProject(companyId: string, operationalUnit: string, name: string, phase: string, observation: string, responsibleId: string): Observable<any> {
    const projectData = {
      projectId: '',
      companyId: companyId,
      operationalUnit: operationalUnit,
      name: name,
      phase: phase,
      observation: observation,
      responsibleId: responsibleId
    };

    return this.http.post(`${this.baseUrl}project`, projectData, this.setHeader(true)).pipe(
      catchError((error) => {
        // console.error('Erro ao adicionar projeto:', error);
        return of(null);
      })
    );
  }

  editProject(projectId: string, companyId: string, name: string, phase: string, observation: string, responsibleId: string): Observable<any> {
    const projectData = {
      projectId: projectId,
      companyId: companyId,
      operationalUnit: projectId,
      name: name,
      phase: phase,
      observation: observation,
      responsibleId: responsibleId
    };

    return this.http.put(`${this.baseUrl}project`, projectData, this.setHeader(true)).pipe(
      catchError((error) => {
        console.error('Erro ao Editar projeto:', error);
        return of(null);
      })
    );
  }

  addOwner(idsOwners: any[], idProject: any) {
    const projectData = {
      companyId: localStorage.getItem('companyId'),
      usersId: idsOwners,
      projectId: idProject,
    };

    return this.http.post(`${this.baseUrl}project/add-owner`, projectData, this.setHeader(true)).pipe(
      catchError((error) => {
        //console.error('Erro ao adicionar owners:', error);
        return of(null);
      })
    );
  }

  /*removeOwner(idsOwners: any, operationalUnitId: any) {
    const projectData = {
      companyId: localStorage.getItem('companyId'),
      usersId: idsOwners,
      operationalUnitId: operationalUnitId,
    };
    return this.http.delete(`${this.baseUrl}operational-units/remove-owner`, projectData, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro ao remover owners:', error);
          return of(null);
        })
      );
  }*/

  dynamicOperationalUnit(id): Observable<any> {
    return this.http.get(`${this.baseUrl}operational-units/${id}`, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro:', error);
          return of([]);
        })
      );
  }

  listDynamicForms(companyId): Observable<any> {
    return this.http.get(`${this.baseUrl}mongo/dynamic-form?companyId=${companyId}&type=OperationalUnit`, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro:', error);
          return of([]);
        })
      );
  }

  createDynamicFormAnswers(dados): Observable<any> {

    return this.http.post(`${this.baseUrl}mongo/create-dynamic-form-answers`, dados, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro:', error);
          return of([]);
        })
      );
  }

  listDynamicFormProject(companyId): Observable<any> {

    return this.http.get(`${this.baseUrl}mongo/dynamic-form?companyId=${companyId}&type=Project`, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro:', error);
          return of([]);
        })
      );
  }

  listDynamicForm(companyId): Observable<any> {

    return this.http.get(`${this.baseUrl}mongo/dynamic-form?companyId=${companyId}&type=PROJECT`, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro:', error);
          return of([]);
        })
      );
  }

  createFolderProject(dados): Observable<any> {
    return this.http.post(`${this.baseUrl}operational-units/create-folder`, dados, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro:', error);
          return of([]);
        })
      );
  }

  addFolder(dados): Observable<any> {
    return this.http.post(`${this.baseUrl}operational-units/create-folder`, dados, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro:', error);
          return of([]);
        })
      );
  }



}
