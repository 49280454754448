import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { animate, style, transition, trigger } from '@angular/animations';
import { SignInService } from './../sign-in.service';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { User } from '../User.model';
@Component({
  selector: 'app-signin-set-password',
  templateUrl: './signin-set-password.component.html',
  styleUrls: ['./signin-set-password.component.scss'],
  animations: [
    trigger('fade',  [
      transition('*<=>*', [
        style({ opacity: 0 }),
        animate('0.5s', style({ opacity: 1 }))
      ]),
    ])
  ]
})
export class SigninSetPasswordComponent implements OnInit {
  @Input() user: User;
  @Input() accounts: any;
  @Input() uri: string;
  @Output() nextStep: EventEmitter<{}> = new EventEmitter<{}>();
  @ViewChild('signInForm', { static: false }) signInForm: ElementRef;
  passwordFormGroup: FormGroup;
  hide = true;
  api = environment.api + '/oauth2/credential_authorization';
  hasAccounts: boolean;
  activePane;

  constructor(private signInService: SignInService) { }

  ngOnInit() {
   //this.setHistory();
    this.passwordFormGroup = new FormGroup({
      password: new FormControl('')
    });
  }

  /*setHistory() {
    history.pushState({page: 2}, '', 'authorize?' + this.uri);
    window.onpopstate = (event) => {
      this.nextStep.emit({email: '', hasAccounts: false});
    };
  }*/

  goBackToEmail() {
    if (this.accounts.length > 0) {
      this.nextStep.emit({email: '', hasAccounts: true});
    } else {
      this.nextStep.emit({email: '', hasAccounts: false});
    }
  }

  signIn() {
    const passwordControl = this.passwordFormGroup.get('password');
    // if (passwordControl.value !== '') {
    //   this.signInService.credentialsCheck(this.user.email, passwordControl.value).subscribe(() => {
    //     this.signInForm.nativeElement.submit();
    //   }, (error: HttpErrorResponse) => {
    //     passwordControl.setErrors({wrongPassword: true});
    //   });
    // } else {
    //   passwordControl.setErrors({required: true});
    // }
  }

}
