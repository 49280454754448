import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialDesignModule } from '../material-design.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatSidenavModule } from '@angular/material/sidenav';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { RouterModule } from '@angular/router';

import { AppHeaderComponent } from './app-header/app-header.component';
import { SystemPathComponent } from './system-path/system-path.component';
import { TabContentComponent } from './tab-content/tab-content.component';
import { PanelSideComponent } from './panel-side/panel-side.component';
import { TableDataComponent } from './table-data/table-data.component';
import { ResponsibleLabelComponent } from './responsible-label/responsible-label.component';
import { ResponsibleDialogComponent } from './responsible-label/responsible-dialog/responsible-dialog.component';
import { SearchBarComponent } from './app-header/search-bar/search-bar.component';
import { UtilityBarComponent } from './app-header/utility-bar/utility-bar.component';
import { AddOwnerEnterpriseDialogComponent } from '../enterprise/dialog/add-owner-enterprise.component';

@NgModule({
  declarations: [
    SystemPathComponent,
    TableDataComponent,
    AppHeaderComponent,
    TabContentComponent,
    PanelSideComponent,
    ResponsibleLabelComponent,
    ResponsibleDialogComponent,
    SearchBarComponent,
    UtilityBarComponent,
    AddOwnerEnterpriseDialogComponent, 
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialDesignModule,
    FlexLayoutModule,
    MatSidenavModule,
    NgxChartsModule,
    RouterModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialDesignModule,
    FlexLayoutModule,
    NgxChartsModule,
    SystemPathComponent,
    TableDataComponent,
    AppHeaderComponent,
    TabContentComponent,
    PanelSideComponent,
    AddOwnerEnterpriseDialogComponent, 
    RouterModule,
  ],
  entryComponents: [AddOwnerEnterpriseDialogComponent], 
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
