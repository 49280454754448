import { ELEMENT_DATA as AGENCY_EA } from '../../../project/organData';
import { ELEMENT_DATA as PROJECT_EA } from '../../../enterprise/projectData';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SidebarService } from "../../sidebar.service";
import { LoginResponse, SignInService, User } from "../../../login/sign-in/sign-in.service";
import { Router } from '@angular/router';
import { SharedService } from '../../../shared/service/shared.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SideMenuComponent implements OnInit {
  userInfo: LoginResponse;
  user: User;
  projects = PROJECT_EA;
  agencys = AGENCY_EA;
  // permitions = PERMITION_EA;

  constructor(private sideBarService: SidebarService,
    private signinService: SignInService,
    private router: Router,
    private service: SharedService,
    private snackBar: MatSnackBar,
  ) {
    this.getUserResponse();
    this.user = this.signinService.getUserData();
  }


  seeMoreOperationalUnit: any;
  seeMoreProject: any;
  seeMorePermition: any;
  operationalUnits: any[] = [];
  projectsAll: any[] = [];
  enterprise: any[] = [];
  permitionAll: any[] = [];
  showMenu: boolean = false;
  ngOnInit() {
    let profilesId = localStorage.getItem('profilesId');

    let currentCompanyId;
    if (localStorage.getItem('companyId') != null) {
      currentCompanyId = parseInt(localStorage.getItem('companyId') || '');
    } else {
      currentCompanyId = localStorage.getItem('companies')[0];
    }
    let companies = JSON.parse(localStorage.getItem('companies') || '[]');
    if (companies && Array.isArray(companies)) {
      let company = companies.find((item: any) => item.companyId === currentCompanyId);     
      if (company) {
        localStorage.setItem('profilesId', company.profilesId.toString());
        if (company.profilesId == 6) {
          location.href = 'users-list';
        }
      }
    }

    this.showMenu = profilesId && parseInt(profilesId, 10) != 6;
    this.loadListMenu();
  }

  sideMenuToggle() {
    this.sideBarService.toggle();
  }


  loadListMenu() {
    this.service.menus().subscribe((response: any) => {
      if (response) {
        this.operationalUnits = response.operationalUnits.slice(0, 10);
        this.projectsAll = response.projects.slice(0, 10);
        this.permitionAll = response.permitions.slice(0, 10);
  
        this.seeMoreOperationalUnit = response.seeMoreOperationalUnit;
        this.seeMoreProject = response.seeMoreProject;
        this.seeMorePermition = response.seeMorePermition;
      } else {
        this.snackBar.open('Nenhuma lista do menu encontrada', 'Fechar', {
          duration: 1500,
          panelClass: ['snack-bar-success']
        });
      }
    }, (error) => {
      this.snackBar.open('Erro ao carregar lista de menus', 'Fechar', {
        duration: 1500,
        panelClass: ['snack-bar-success']
      });
    });
  }

  closeSideMenu() {
    this.sideBarService.toggle();
  }

  getUserResponse() {
    this.userInfo = this.signinService.getUserResponse();
  }
  changeRoute(route: string) {
    this.router.navigateByUrl('/' + route);
  }

  
  showMessage(message: string, action: string = 'Fechar') {
    this.snackBar.open(message, action, {
      duration: 3000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      panelClass: ['custom-snackbar']
    });
  }

}