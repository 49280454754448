import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';


import { SigninSetEmailComponent } from './sign-in/signin-set-email/signin-set-email.component';
import { SigninSetPasswordComponent } from './sign-in/signin-set-password/signin-set-password.component';
import { ChooseAccountsComponent } from './sign-in/choose-accounts/choose-accounts.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { RemoveAccountDialogComponent } from './sign-in/choose-accounts/remove-account-dialog/remove-account-dialog.component';

import { SharedModule } from '../shared/shared.module';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from '../app-routing.module';
import { CookieModule } from 'ngx-cookie';
import { RecoverPasswordComponent } from './recover-password/recover-password.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    HttpClientModule,
    AppRoutingModule,
    CookieModule.forRoot()
  ],
  declarations: [
    SignInComponent,
    RecoverPasswordComponent,
    SigninSetEmailComponent,
    SigninSetPasswordComponent,
    ChooseAccountsComponent,
    RemoveAccountDialogComponent
  ],
  entryComponents: [
    RemoveAccountDialogComponent
  ]
})
export class LoginModule { }
