import { Component, OnInit, Input, ChangeDetectionStrategy  } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-system-path',
  templateUrl: './system-path.component.html',
  styleUrls: ['./system-path.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SystemPathComponent implements OnInit {

  @Input() path: any;

  constructor() { }

  ngOnInit() {  }

  isNotFirst(node) {
    return Boolean(node === this.path[0]);
  }
}
