export const ELEMENT_DATA = [
  {
    context: {
      name: 'Desativados',
      class: 'desactivated'
    },
    tab: 'Projetos',
    displayedColumns: ['select', 'Projeto', 'Responsáveis', 'Pendências', 'Ações'],
    mobileDisplayedColumns: ['Projeto', 'Responsáveis', 'Ações'],
    data: [
      {number: 1, name: 'Projeto Licentia', pendencias: '6',
        responsaveis: [
          {name: 'Ighor Carlos'}, {name: 'Carlos Alberto'}, {name: 'Marina Lazzarini'}, {name: 'Renato Ceolin'}, {name: 'Mais 1'}
        ],
        route: 'project'},
      {number: 2, name: 'Forno de carbonizacao	', pendencias: null,
        responsaveis: [
          {name: 'Ighor Carlos'}, {name: 'Carlos Alberto'}, {name: 'Marina Lazzarini'}, {name: 'Renato Ceolin'}, {name: 'Mais 1'}
        ],
        route: 'project'},
      {number: 3, name: 'Mina Moroca	', pendencias: 3,
        responsaveis: [
          {name: 'Ighor Carlos'}, {name: 'Carlos Alberto'}, {name: 'Marina Lazzarini'}, {name: 'Renato Ceolin'}, {name: 'Mais 1'}
        ],
        route: 'project'},
      {number: 4, name: 'Mina Santa Helena	', pendencias: null,
        responsaveis: [
          {name: 'Ighor Carlos'}, {name: 'Carlos Alberto'}, {name: 'Marina Lazzarini'}, {name: 'Renato Ceolin'}, {name: 'Mais 1'}
        ],
        route: 'project'},
      {number: 5, name: 'Projeto de Expansão	', pendencias: null,
        responsaveis: [
          {name: 'Ighor Carlos'}, {name: 'Carlos Alberto'}, {name: 'Marina Lazzarini'}, {name: 'Renato Ceolin'}, {name: 'Mais 1'}],
        route: 'project'},
      {number: 6, name: 'REDE COMPLEMENTAR	', pendencias: null,
        responsaveis: [
          {name: 'Ighor Carlos'}, {name: 'Carlos Alberto'}, {name: 'Marina Lazzarini'}, {name: 'Renato Ceolin'}, {name: 'Mais 1'}],
        route: 'project'},
    ]
  },
  {
    context: {
      name: 'Em Desenvolvimento',
      class: 'development'
    },
    tab: 'Projetos',
    displayedColumns: ['select', 'Projeto', 'Responsáveis', 'Pendências', 'Ações'],
    mobileDisplayedColumns: ['Projeto', 'Responsáveis', 'Ações'],
    data: [
      {
        number: 1, name: 'Projeto de Expansão', pendencias: '6',
        responsaveis: [
          {name: 'Ighor Carlos'}, {name: 'Carlos Alberto'}, {name: 'Marina Lazzarini'}, {name: 'Renato Ceolin'}, {name: 'Mais 1'}
        ],
        route: 'project'
      },
      {
        number: 2, name: 'Forno de carbonizacao', pendencias: null,
        responsaveis: [
          {name: 'Ighor Carlos'}, {name: 'Carlos Alberto'}, {name: 'Marina Lazzarini'}, {name: 'Renato Ceolin'}, {name: 'Mais 1'}
        ],
        route: 'project'
      },
      {
        number: 3, name: 'REDE COMPLEMENTAR', pendencias: 3,
        responsaveis: [
          {name: 'Ighor Carlos'}, {name: 'Carlos Alberto'}, {name: 'Marina Lazzarini'}, {name: 'Renato Ceolin'}, {name: 'Mais 1'}
        ],
        route: 'project'
      },
      {number: 4, name: 'Mina Santa Helena', pendencias: null,
        responsaveis: [
          {name: 'Ighor Carlos'}, {name: 'Carlos Alberto'}, {name: 'Marina Lazzarini'}, {name: 'Renato Ceolin'}, {name: 'Mais 1'}
        ],
        route: 'project'},
      {number: 6, name: 'REDE carbonizacao', pendencias: null,
        responsaveis: [
          {name: 'Ighor Carlos'}, {name: 'Carlos Alberto'}, {name: 'Marina Lazzarini'}, {name: 'Renato Ceolin'}, {name: 'Mais 1'}
        ],
        route: 'project'},
    ]
  },
  {
    context: {
      name: 'Implantação',
      class: 'instalation'
    },
    tab: 'Projetos',
    displayedColumns: ['select', 'Projeto', 'Responsáveis', 'Pendências', 'Ações'],
    mobileDisplayedColumns: ['Projeto', 'Responsáveis', 'Ações'],
    data: [
      {number: 2, name: 'Forno de carbonizacao	', pendencias: null,
        responsaveis: [
          {name: 'Ighor Carlos'}, {name: 'Carlos Alberto'}, {name: 'Marina Lazzarini'}, {name: 'Renato Ceolin'}, {name: 'Mais 1'}],
        route: 'project'},
      {number: 3, name: 'Mina Moroca', pendencias: 3,
        responsaveis: [
          {name: 'Ighor Carlos'}, {name: 'Carlos Alberto'}, {name: 'Marina Lazzarini'}, {name: 'Renato Ceolin'}, {name: 'Mais 1'}
        ],
        route: 'project'}
    ]
  },
  {
    context: {
      name: 'Em Operação',
      class: 'operation'
    },
    tab: 'Projetos',
    displayedColumns: ['select', 'Projeto', 'Responsáveis', 'Pendências', 'Ações'],
    mobileDisplayedColumns: ['Projeto', 'Responsáveis', 'Ações'],
    data: [
      {number: 1, name: 'Ativado', pendencias: '6',
        responsaveis: [
          {name: 'Ighor Carlos'}, {name: 'Carlos Alberto'}, {name: 'Marina Lazzarini'}, {name: 'Renato Ceolin'}, {name: 'Mais 1'}
        ],
        route: 'project'},
      {number: 2, name: 'Forno de carbonizacao	', pendencias: null,
        responsaveis: [
          {name: 'Ighor Carlos'}, {name: 'Carlos Alberto'}, {name: 'Marina Lazzarini'}, {name: 'Renato Ceolin'}, {name: 'Mais 1'}],
        route: 'project'},
      {number: 3, name: 'Mina Moroca	', pendencias: 3,
        responsaveis: [
          {name: 'Ighor Carlos'}, {name: 'Carlos Alberto'}, {name: 'Marina Lazzarini'}, {name: 'Renato Ceolin'}, {name: 'Mais 1'}],
        route: 'project'},
      {number: 4, name: 'Mina Santa Helena	', pendencias: null,
        responsaveis: [
          {name: 'Ighor Carlos'}, {name: 'Carlos Alberto'}, {name: 'Marina Lazzarini'}, {name: 'Renato Ceolin'}, {name: 'Mais 1'}],
        route: 'project'}
    ]
  }
];
