import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements OnInit {
  @Input() placeholder: string;
  @Output() seachValue = new EventEmitter();

  constructor() { }

  ngOnInit() { }

  sendSearchData(filterValue: string) {
    this.seachValue.emit(filterValue);
  }

}
