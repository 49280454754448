import { Injectable } from '@angular/core';
import { MatSidenav } from '@angular/material';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, of, Observable  } from 'rxjs';
import { environment } from '../../environments/environment';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class SidebarService {

  constructor(private http: HttpClient) { }

  public menuState = new BehaviorSubject <boolean>(false);
  private sidenav: MatSidenav;
  private baseUrl: string = environment.api;
  setSidenav(sidenav: MatSidenav) {
    this.sidenav = sidenav;
  }

  open() {
    return this.sidenav.open();
  }


  close() {
    return this.sidenav.close();
  }

  toggle(): void {
    this.sidenav.toggle();
    localStorage.setItem('sidenav-state', this.sidenav.opened.toString());
  }

  getSideBarState() {
    if (localStorage.getItem('sidenav-state') === 'true') {
      this.menuState.next(true);
      return true;
    } else {
      this.menuState.next(false);
      return false;
    }
    // return localStorage.getItem('sidenav-state') === 'true' ? true : false;
  }



}
