import { map, startWith } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';

@Injectable({
  providedIn: 'root'
})
export class MediaQueryService {

  constructor(private gridMedia: MediaObserver) { }

  setMediQueryGrid(grid: any, start: any) {
    grid.forEach((cols: any, mqAlias: any) => {
      if (this.gridMedia.isActive(mqAlias)) {
        start = cols;
      }
    });
  }

  setGridMedia(grid: any, start: any) {
    return this.gridMedia.asObservable().pipe(
      map(change => {
        return grid.get(change[0].mqAlias);
      }),
      startWith(start)
    );
  }
}
