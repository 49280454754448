import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ResponsibleDialogComponent } from './responsible-dialog/responsible-dialog.component';

@Component({
  selector: 'app-responsible-label',
  templateUrl: './responsible-label.component.html',
  styleUrls: ['./responsible-label.component.scss']
})
export class ResponsibleLabelComponent implements OnInit {
  @Input() users: any;

  constructor(public dialog: MatDialog) { }

  ngOnInit() { }

  showResponsibles() {
    const dialogRef = this.dialog.open(ResponsibleDialogComponent, {
      data: { users: this.users },
      width: '725px'
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }
}
