import { Component, OnInit, ChangeDetectorRef, TemplateRef, Input, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import {SidebarService} from "../../core/sidebar.service";

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class AppHeaderComponent implements OnInit {
  private mobileQueryListener: () => void;
  mobileQuery: MediaQueryList;
  opened: boolean = this.sideBarService.getSideBarState() ? this.sideBarService.getSideBarState() : false;
  openedPreviewState: boolean;
  margin: string;
  path = ['Empreendimento Licentia'];
  @Input() tabs: any;
  @Input() screenData: any;
  @Input() panelData: any;
  @Input() template: TemplateRef<any>;
  @Output() filterData = new EventEmitter();
  openedPanel = false;

  constructor(public sideBarService: SidebarService, changeDetectorRef: ChangeDetectorRef,
              media: MediaMatcher) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this.mobileQueryListener = () => changeDetectorRef.detectChanges();
    if (this.mobileQuery.matches) {
      this.opened = false;
      localStorage.setItem('sidenav-state', 'false');
    }
    this.sideBarService.menuState.subscribe((state) => {
      this.openedPreviewState = this.opened;
      this.opened = state;
    });
  }

  ngOnInit() { }


  changeMargin() {
    this.opened || this.mobileQuery.matches ? this.margin = '0' : this.margin = '50px';
    return this.margin;
  }

  openPanel() {
    this.openedPanel = !this.openedPanel;
    console.log(this.openedPanel);
  }

  closePanel(event: any) {
    console.log(event, 'event received');
    this.openedPanel = event.opened;
    console.log(this.openedPanel, 'openPanel after event');
  }

  getFilterData(filterValue: string) {
    this.filterData.emit(filterValue);
  }

  legendColor(legend: string) {
    switch (legend) {
      case 'Não cumprida':
        return 'alert-icon';
      case 'Em cumprimento, atrasada':
        return 'late-icon';
      case 'Em cumprimento, em dia':
        return 'fine-icon';
      case 'Recorrente':
        return 'loop-icon';
      case 'Aviso de alterações':
        return 'alteration-icon';
      case 'Cumprida':
        return 'finished-icon';
      case 'Cumprida com atraso':
        return 'late-finished-icon';
    }
  }
}

