import { Component, OnInit, OnDestroy, NgZone } from '@angular/core';
import { SidebarService } from "../../sidebar.service";
import { LoginResponse, SignInService, User } from "../../../login/sign-in/sign-in.service";
import { CompanyDialogComponent } from "../company-dialog/company-dialog.component";
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie';
import { OperationUnitService } from '../../../operational-unit/service/operation-unit.service';

export type Company = {
  company: string;
  companyId: number
};

// export interface User {
//   name: string;
//   email: string;
//   password: string;
//   token: string;
// }

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {
  isCompanyListVisible = false;
  opened: any;
  user: User;
  companies: any[] = [];
  company: Company;
  selectedCompany: any;
  isDropdownVisible = false;

  userMenuOptions = [
    { label: 'Ambiente do usuário', icon: 'dashboard', link: '/perfil' },
    {
      label: 'Gerenciamento do Sistema', icon: 'settings', submenu: [
        { label: 'Edição de Formulário', icon: 'edit', link: 'free-columns' },
        { label: 'Logs', icon: 'history', link: 'logs/8' }
      ]
    },
    { label: 'Enviar Feedback', icon: 'feedback', link: 'https://www.licentia.digital/fale-com-um-especialista' },
    { label: 'Sair', icon: 'exit_to_app', link: 'logout' }
  ];


  constructor(
    private signInService: SignInService,
    private ngZone: NgZone,
    private cookieService: CookieService,
    private sideBarService: SidebarService,
    private signinService: SignInService,
    private router: Router,
    private service: OperationUnitService) {
    this.user = this.signinService.getUserData();
    this.getCompanies();
    this.company = this.signinService.getCompaniesData();

  }
  operationalUnits: any[] = [];


  ngOnInit() {

    this.companies = this.signinService.getCompaniesData();
    const storedCompanyName = localStorage.getItem('company');
    const storedCompanyId = localStorage.getItem('companyId');


    if (storedCompanyName && storedCompanyId) {
      this.selectedCompany = this.companies.find(company => company.company === storedCompanyName && company.companyId.toString() === storedCompanyId);
    }

    if (!this.selectedCompany && this.companies.length > 0) {
      this.selectedCompany = this.companies[0];
      localStorage.setItem('company', this.selectedCompany.company);
      localStorage.setItem('companyId', this.selectedCompany.companyId.toString());
    }
    this.loadOperationalUnitsAll();
  }

  toggleDropdown() {
    this.isDropdownVisible = !this.isDropdownVisible;
  }

  sideMenuToggle() {
    this.sideBarService.toggle();
  }

  signOut() {
    localStorage.clear();
    this.router.navigateByUrl('/');
  }

  getCompanies() {
    this.companies = this.signinService.getCompaniesData();

    if (this.companies && this.companies.length > 0) {
      let firstCompany = this.companies[0];
    } else {
      console.log('Nenhuma Companhia Encontrada');
    }

    // this.companiesGQL.watch().valueChanges.subscribe(({ data }) => {
    //   this.companies = data['companies'];
    //   if (!localStorage.getItem('company') && this.companies.length > 1) {
    //     this.selectCompanyDialog();
    //   } else if (this.companies.length === 1) {
    //     this.selectCompany(this.companies[0], false);
    //   } else {
    //     this.selectCompany(this.companies.find((company: any) => company.name === localStorage.getItem('company')), false);
    //   }
    // });
  }



  selectCompanyDialog() {
    // const dialogRef = this.dialog.open(CompanyDialogComponent, {
    //   width: '700px',
    //   data: { companies: this.companies },
    //   disableClose: true
    // });
    // dialogRef.afterClosed().subscribe(company => {
    //   company ? this.selectCompany(company, false) : this.selectCompany(this.companies[0], false);
    // });
  }

  selectCompany(company: any, change: boolean) {
    this.selectedCompany = company;
    localStorage.setItem('company', company.company); // Armazenar o nome da empresa no localStorage
    localStorage.setItem('companyId', company.companyId.toString()); // Armazenar o id da empresa no localStorage

    if (change) {
      this.isDropdownVisible = false;
      // this.notificationService.info('Você mudou seu ambiente para a empresa ' + company.name, 'Clique aqui para fechar', {timeOut: 1600});
      location.href = 'all/OperationalUnit';

    }
  }

  selectUserOption(option: { label: string; icon: string; link: string }) {
    switch (option.label) {
      case 'Sair':
        this.signOut();
        break;
      default:
        this.router.navigate([option.link]); // Navega para a rota correspondente
        break;
    }
  }

  ngOnDestroy() {
    //this.authSubscription.unsubscribe();
  }


  loadOperationalUnitsAll() {
    this.service.operationalUnitsAll().subscribe((response: any) => {
      if (response && response.operationalUnit) {
        this.operationalUnits = response.operationalUnit.map((unit: any) => ({
          id: unit.id,
          nameCompany: unit.name ? unit.name : '',
        }));

      } else {
        console.error('Nenhuma unidade operacional encontrada:', response);
      }
    }, (error) => {
      console.error('Erro ao carregar unidades operacionais:', error);
    });
  }


}
