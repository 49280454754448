import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AbstractControl, FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SharedService } from '../../shared/service/shared.service';
import { HttpClient } from '@angular/common/http';


@Component({
  selector: 'app-edit-dialog',
  templateUrl: './add-company-component.html',
  styleUrls: ['../company.component.scss']
})
export class AddCompanyComponent implements OnInit {
  companyForm: FormGroup;
  selectedManager: any;
  dynamicForm: any[] = [];
  keysArray: any[] = [];
  projectId: any;
  selectedUserId: any;
  staticAnswers: any;
  idDyanamic: any;
  cnpj = new FormControl('', this.validarCNPJ.bind(this));
  cepControl = new FormControl('', [Validators.required, this.validarCEP]);
  passwordControl = new FormControl('', [Validators.required, this.validarSenha]);
  submitted = false;
  recaptchaResponse: string | null = null;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<AddCompanyComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private service: SharedService,
    private snackBar: MatSnackBar,
    private http: HttpClient,
  ) {



  }

  ngOnInit(): void {
    this.loadRecaptchaScript();
    this.companyForm = this.fb.group({
      company: this.fb.group({
        companyName: ['', Validators.required],
        tradingName: ['', Validators.required],
        cnpj: ['', Validators.required],
        businessType: ['', Validators.required],
        dateOfEstablishment: [''],
        phone: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        address: this.fb.group({
          street: ['', Validators.required],
          number: ['', Validators.required],
          neighborhood: ['', Validators.required],
          city: ['', Validators.required],
          stateOrProvince: ['', Validators.required],
          postalCode: ['', Validators.required],
          country: ['', Validators.required]
        })
      }),
      user: this.fb.group({
        name: ['', Validators.required],
        phone: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        password: ['', [Validators.required, Validators.minLength(6)]]
      })

    });
  }


  loadRecaptchaScript(): void {
    if (!document.querySelector('script[src="https://www.google.com/recaptcha/api.js"]')) {
      const script = document.createElement('script');
      script.src = 'https://www.google.com/recaptcha/api.js';
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    this.submitted = true;

    if (!this.recaptchaResponse) {
      alert('Por favor, preencha todos os campos e complete o reCAPTCHA.');
      return;
    }

    if (this.companyForm.valid) {

      this.http.get('https://api64.ipify.org?format=json').subscribe((res: any) => {
        const userIp = res.ip;

        const updatedData = {
          ...this.companyForm.value,
          pricingPlanId: this.data.id,
          ip: userIp
        };

        console.log('Dados do formulário:', updatedData);

        this.service.createCompany(updatedData).subscribe(response => {
          if (response.status == 400) {
            this.snackBar.open('Email Inválido ou Já Cadastrado', 'Fechar', { duration: 1500, panelClass: ['snack-bar-warning'] });
          } else if (response.status == 200) {
            this.snackBar.open('Empresa cadastrada com sucesso!', 'Fechar', { duration: 1500, panelClass: ['snack-bar-success'] });
            this.dialogRef.close();
            setTimeout(() => window.location.href = "/", 1500);
          } else {
            this.snackBar.open('Ocorreu um erro, tente novamente mais tarde', 'Fechar', { duration: 1500, panelClass: ['snack-bar-error'] });
          }
        }, error => {
          this.snackBar.open('Erro ao cadastrar empresa!', 'Fechar', { duration: 1500, panelClass: ['snack-bar-error'] });
        });

      }, error => {
        console.error('Erro ao obter IP:', error);
        this.snackBar.open('Erro ao obter IP do usuário!', 'Fechar', { duration: 1500, panelClass: ['snack-bar-error'] });
      });

    } else {
      this.snackBar.open('Preencha todos os campos obrigatórios!', 'Fechar', { duration: 1500, panelClass: ['snack-bar-warning'] });
    }
  }


  formatarCNPJ() {
    let valor = this.cnpj.value.replace(/\D/g, '');

    if (valor.length > 14) valor = valor.substring(0, 14);

    if (valor.length > 2) valor = valor.replace(/^(\d{2})/, '$1.');
    if (valor.length > 5) valor = valor.replace(/^(\d{2})\.(\d{3})/, '$1.$2.');
    if (valor.length > 8) valor = valor.replace(/^(\d{2})\.(\d{3})\.(\d{3})/, '$1.$2.$3/');
    if (valor.length > 12) valor = valor.replace(/^(\d{2})\.(\d{3})\.(\d{3})\/(\d{4})/, '$1.$2.$3/$4-');

    this.cnpj.setValue(valor, { emitEvent: false });
  }

  validarCNPJ(control: FormControl) {
    const cnpj = (control.value || '').replace(/\D/g, '');
    if (cnpj.length !== 14 || !this.isCnpjValid(cnpj)) {
      return { cnpjInvalido: true };
    }
    return null;
  }

  private isCnpjValid(cnpj: string): boolean {
    let tamanho = cnpj.length - 2;
    let numeros = cnpj.substring(0, tamanho);
    let digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;

    for (let i = tamanho; i >= 1; i--) {
      soma += +numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) pos = 9;
    }

    let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado !== +digitos.charAt(0)) return false;

    tamanho++;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;

    for (let i = tamanho; i >= 1; i--) {
      soma += +numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) pos = 9;
    }

    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    return resultado === +digitos.charAt(1);
  }


  buscarCep(): void {
    let cep = this.companyForm.get('company.address.postalCode').value || '';

    cep = cep.replace(/\D/g, '');

    if (cep.length !== 8) {
      return;
    }

    this.http.get(`https://viacep.com.br/ws/${cep}/json/`).subscribe(
      (dados: any) => {
        if (!dados.erro) {
          this.companyForm.patchValue({
            company: {
              address: {
                street: dados.logradouro,
                neighborhood: dados.bairro,
                city: dados.localidade,
                stateOrProvince: dados.uf
              }
            }
          });

          // Formata o CEP novamente após buscar os dados
          this.formatarCEP();
        } else {
          this.snackBar.open('CEP não encontrado!', 'Fechar', {
            duration: 1500,
            panelClass: ['snack-bar-warning']
          });
        }
      },
      () => {
        this.snackBar.open('Erro ao buscar o CEP!', 'Fechar', {
          duration: 1500,
          panelClass: ['snack-bar-error']
        });
      }
    );
  }

  formatarCEP() {
    let valor = this.companyForm.get('company.address.postalCode').value || '';

    valor = valor.replace(/\D/g, '');

    if (valor.length > 8) {
      valor = valor.substring(0, 8);
    }

    if (valor.length > 5) {
      valor = valor.replace(/^(\d{5})(\d{0,3})/, '$1-$2');
    }

    this.companyForm.get('company.address.postalCode').setValue(valor, { emitEvent: false });
  }

  validarCEP(control: FormControl) {
    const cep = (control.value || '').replace(/\D/g, '');
    return cep.length === 8 ? null : { cepInvalido: true };
  }

  validarSenha(control: FormControl) {
    const senha = control.value || '';

    const temMaiuscula = /[A-Z]/.test(senha);
    const temMinuscula = /[a-z]/.test(senha);
    const temNumero = /\d/.test(senha);
    const temSimbolo = /[@$!%*?&]/.test(senha);

    if (!temMaiuscula || !temMinuscula || !temNumero || !temSimbolo) {
      return { senhaInvalida: true };
    }

    return null;
  }

}
