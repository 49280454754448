import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RecoverPasswordService } from './recover-password.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Params, Router, UrlSerializer } from '@angular/router';
@Component({
  selector: 'app-recover-password',
  templateUrl: './recover-password.component.html',
  styleUrls: ['./recover-password.component.scss'],
})

export class RecoverPasswordComponent implements OnInit {
  recoverPasswordFormGroup: FormGroup;
  recaptchaResponse: string | null = null;
  isSubmitted: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private service: RecoverPasswordService,
    private snackBar: MatSnackBar,
    private router: Router,
  ) {

  }

  ngOnInit(): void {
    this.loadRecaptchaScript();
    this.recoverPasswordFormGroup = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  loadRecaptchaScript(): void {
    if (!document.querySelector('script[src="https://www.google.com/recaptcha/api.js"]')) {
      const script = document.createElement('script');
      script.src = 'https://www.google.com/recaptcha/api.js';
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
    }
  }

  onCaptchaLoaded(response: string): void {
    this.recaptchaResponse = response;
  }

  onCaptchaExpired(): void {
    this.recaptchaResponse = null;
    console.log('reCAPTCHA expirado.');
  }


  onSubmit(): void {
    if (this.recoverPasswordFormGroup.invalid ) {
      alert('Por favor, preencha todos os campos e complete o reCAPTCHA.');
      return;
    }
    /* if (!this.recoverPasswordFormGroup || this.recoverPasswordFormGroup.invalid) {
      Object.keys(this.recoverPasswordFormGroup.controls).forEach((field) => {
        const control = this.recoverPasswordFormGroup.get(field);
        if (control) {
          control.markAsTouched({ onlySelf: true });
        }
      });
      alert('Por favor, preencha todos os campos e complete o reCAPTCHA.');
      return;
    }*/

    const emailControl = this.recoverPasswordFormGroup.get('email');
    const email = emailControl ? emailControl.value : null;

    this.service.sendRecoveryEmail(email, this.recaptchaResponse).subscribe(
      (response) => {
        if (response.status == 200) {
          this.snackBar.open('Email enviado com sucesso', 'Fechar', {
            duration: 2000,
            panelClass: ['snack-bar-success']
          });
          this.isSubmitted = true;
          this.router.navigate(['/']);
        } else {
          this.snackBar.open('Ocoreu um erro ao processar, tente novamente mais tarde', 'Fechar', {
            duration: 2000,
            panelClass: ['snack-bar-success']
          });
          this.router.navigate(['/']);
        }
      },
      (error) => {
        this.snackBar.open('Houve um problema para enviar email, tente novamente mais tarde', 'Fechar', {
          duration: 1500,
          panelClass: ['snack-bar-success']
        });
        this.isSubmitted = true;
      }
    );
  }

  showMessage(message: string, action: string = 'Fechar') {
    this.snackBar.open(message, action, {
      duration: 3000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      panelClass: ['custom-snackbar']
    });
  }

}
