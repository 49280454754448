import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { SignInService } from '../sign-in.service';
import { User } from '../User.model';
import { MatDialog } from '@angular/material';
import { RemoveAccountDialogComponent } from './remove-account-dialog/remove-account-dialog.component';
import { environment } from '../../../../environments/environment';
@Component({
  selector: 'app-choose-accounts',
  templateUrl: './choose-accounts.component.html',
  styleUrls: ['./choose-accounts.component.scss'],
  animations: [
    trigger('fade',  [
      transition('*<=>*', [
        style({ opacity: 0 }),
        animate('0.5s', style({ opacity: 1 }))
      ]),
    ])
  ]
})
export class ChooseAccountsComponent implements OnInit {
  @Input() accounts: any;
  @Input() user: User;
  @Output() backStep: EventEmitter<{}> = new EventEmitter<{}>();
  @ViewChild('signInForm', { static: false }) signInForm: ElementRef;
  userEmail: string;
  activePane: any;
  accountsExpired = [];
  removeAccount = false;
  api = environment.api + '/oauth2/session_authorization';

  constructor(private signInService: SignInService, public dialog: MatDialog) { }

  ngOnInit() {
    this.verifySessions();
  }

  verifySessions() {
    if (this.accounts) {
      this.accounts.map((account, i) => {
        account.active ? this.accountsExpired[i] = false : this.accountsExpired[i] = true;
      });
    }
  }

  logInWithThisAccount(i) {
    if (this.accountsExpired[i] === true || this.user.prompt === 'login') {
      this.backStep.emit({email: this.accounts[i].email, nextStep: true, hasAccounts: false});
    } else {
      this.userEmail = this.accounts[i].email;
      setTimeout(() => this.signInForm.nativeElement.submit(), 100);
    }
  }

  logInWithAnotherAccount() {
    this.backStep.emit({email: null, nextStep: false, hasAccounts: false});
  }

  goToRemoveAccounts() {
    this.removeAccount = true;
    this.backStep.emit({removeAccounts: true, nextStep: false, hasAccounts: true});
  }

  goBackToChooseAccounts() {
    this.removeAccount = false;
    this.backStep.emit({email: '', hasAccounts: true});
  }

  chooseOptions(i) {
    !this.removeAccount ? this.logInWithThisAccount(i) : this.openRemoveDialog(i);
  }

  removeThisAccount(i) {
    this.signInService.removeAccount(this.accounts[i].session_token).subscribe(() => {
      this.accounts.splice(i, 1);
      if (this.accounts.length === 0) {
        this.backStep.emit({email: null, nextStep: false, hasAccounts: false});
      }
    });
  }

  openRemoveDialog(i) {
    const dialogRef = this.dialog.open(RemoveAccountDialogComponent, {
      width: '336px'
    });
    dialogRef.afterClosed().subscribe((confirmation) => {
      if (confirmation) {
        this.removeThisAccount(i);
      }
    });
  }
}
