import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {PortalModule} from '@angular/cdk/portal';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {CdkStepperModule} from '@angular/cdk/stepper';
import {CdkTableModule} from '@angular/cdk/table';
import {CdkTreeModule} from '@angular/cdk/tree';
import { MatToolbarModule, MatCardModule, MatFormFieldModule, MatInputModule, MatButtonModule,
  MatDialogModule, MatDividerModule, MatIconModule, MatTooltipModule, MatListModule,
  MatProgressSpinnerModule, MatProgressBarModule, MatExpansionModule, MatSidenavModule,
  MatBadgeModule, MatGridListModule, MatTabsModule, MatTableModule, MatMenuModule, MatCheckboxModule,
  MatPaginatorModule, MatTreeModule } from '@angular/material';
import 'hammerjs';

@NgModule({
  declarations: [],
  imports: [
    CommonModule, MatToolbarModule, MatCardModule, MatFormFieldModule, MatInputModule, MatButtonModule, MatDividerModule, MatIconModule,
    MatTooltipModule, MatListModule, MatProgressSpinnerModule, MatProgressBarModule, MatDialogModule, MatExpansionModule, MatSidenavModule,
    MatBadgeModule, MatGridListModule, MatTabsModule, MatTableModule, MatMenuModule, MatCheckboxModule, MatPaginatorModule, MatTreeModule,
    DragDropModule, PortalModule, ScrollingModule, CdkStepperModule, CdkTableModule, CdkTreeModule
  ],
  exports: [
    MatToolbarModule, MatCardModule, MatFormFieldModule, MatInputModule, MatButtonModule, MatDividerModule, MatIconModule, MatTooltipModule,
    MatListModule, MatProgressSpinnerModule, MatProgressBarModule, MatDialogModule, MatExpansionModule, MatSidenavModule, MatBadgeModule,
    MatGridListModule, MatTabsModule, MatTableModule, MatMenuModule, MatCheckboxModule, MatPaginatorModule, MatTreeModule, DragDropModule,
    PortalModule, ScrollingModule, CdkStepperModule, CdkTableModule, CdkTreeModule
  ]
})
export class MaterialDesignModule { }
