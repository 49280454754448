import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ValidateEmailNotTaken } from '../../../shared/validators/async-email.validator';
import { SignInService } from './../sign-in.service';
import { animate, style, transition, trigger } from '@angular/animations';
import { User } from '../User.model';

const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

@Component({
  selector: 'app-signin-set-email',
  templateUrl: './signin-set-email.component.html',
  styleUrls: ['./signin-set-email.component.scss'],
  animations: [
    trigger('fade',  [
      transition('*<=>*', [
        style({ opacity: 0 }),
        animate('0.5s', style({ opacity: 1 }))
      ]),
    ])
  ]
})
export class SigninSetEmailComponent implements OnInit {
  @Output() nextStep: EventEmitter<{}> = new EventEmitter<{}>();
  @Input() user: User;
  @Input() accounts: any;
  @Input() uri: string;
  emailFormGroup: FormGroup;
  activePane;

  constructor(private signInService: SignInService) { }

  ngOnInit() {
    //this.setHistory();
    // this.emailFormGroup = new FormGroup({
    //   email: new FormControl('', {
    //     validators: [ Validators.required, Validators.pattern(EMAIL_REGEX) ],
    //     asyncValidators: [ ValidateEmailNotTaken.createValidator(this.signInService) ],
    //   })
    // });
  }

  /*setHistory() {
    history.pushState({page: 1}, '', 'authorize?' + this.uri);
    window.onpopstate = (event) => {
      if (this.accounts.length > 0) {
        this.nextStep.emit({email: '', hasAccounts: true});
      } else {
        this.nextStep.emit({email: '', hasAccounts: false});
      }
    };
  }*/

  onSubmit(value, isValid) {
    if (isValid) {
      this.nextStep.emit({email: value.email, nextStep: true});
    } else {
      this.nextStep.emit({email: null, nextStep: false});
    }
  }
}
