import { MediaMatcher } from '@angular/cdk/layout';
import { Component, OnInit, Input, OnChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource, MatPaginator } from '@angular/material';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-table-data',
  templateUrl: './table-data.component.html',
  styleUrls: ['./table-data.component.scss']
})
export class TableDataComponent implements OnInit, OnChanges {
  @Input() context: any;
  @Input() data: any;
  @Input() tab: any;
  @Input() filter: string;

  // @ts-ignore

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;



  dataSource: any;
  displayedColumns: string[];
  mobileQuery: MediaQueryList;
  selection = new SelectionModel<any>(true, []);

  constructor(media: MediaMatcher, private router: Router) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
  }

  ngOnInit() {
    this.data.forEach((element) => {
      if (element.context.name === this.context  && element.tab === this.tab) {
        this.dataSource = new MatTableDataSource(element.data);
        if (this.mobileQuery.matches) {
          this.displayedColumns = element.mobileDisplayedColumns;
        } else {
          this.displayedColumns = element.displayedColumns;
        }
        this.dataSource.paginator = this.paginator;
      }
    });
  }

  ngOnChanges() {
    if (this.dataSource) {
      this.applyFilter();
    }
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach((row: any) => this.selection.select(row));
  }

  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  columnClass(index: number) {
    if (this.displayedColumns[index] === 'Pendências') {
      return 'badge-container';
    } else if (index === 0 && this.displayedColumns[index] === 'select' || this.displayedColumns[index] === 'legend') {
      return 'select-column';
    } else if (index === 1 && this.displayedColumns[0] === 'select' && this.displayedColumns[1] === 'Mark') {
      return 'select-column';
    } else if (index === 1 && this.displayedColumns[0] === 'select') {
      return 'project-column';
    } else if (index === 0 && this.displayedColumns[0] !== 'select') {
      return 'project-column';
    } else if (this.displayedColumns[index] === 'Responsáveis') {
      return 'responsibles-column';
    }
  }

  applyFilter() {
    this.dataSource.filter = this.filter.trim().toLowerCase();
  }

  changeRoute(route: string, id: string) {
    this.router.navigateByUrl('/' + route);
  }

  legendColor(legend: string) {
    switch (legend) {
      case 'Não cumprida':
        return 'alert-icon';
      case 'Em cumprimento, atrasada':
        return 'late-icon';
      case 'Em cumprimento, em dia':
        return 'fine-icon';
      case 'Recorrente':
        return 'loop-icon';
      case 'Aviso de alterações':
        return 'alteration-icon';
      case 'Cumprida':
        return 'finished-icon';
      case 'Cumprida com atraso':
        return 'late-finished-icon';
    }
  }
}

