import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { User } from '../sign-in/User.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RecoverPasswordService {
  token;

  constructor(private http: HttpClient, private route: ActivatedRoute) {
    this.route.queryParams.subscribe(params => {
      this.token = params.token;
  });
  }

  private setHeader(withCredentials: boolean) {
    return {
      headers: new HttpHeaders({
        'Cache-Control': 'no-cache'
      }),
      withCredentials
    };
  }

  sendRecoveryEmail(email: string) {
    return this.http.post(`${environment.api}/sign_in_service/recover_email`, { email });
  }
}
