import {AfterViewInit, ChangeDetectorRef, Component, DoCheck, OnDestroy, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {SidebarService} from "../../core/sidebar.service";
import {MediaMatcher} from "@angular/cdk/layout";
import { Router } from "@angular/router";

import { MediaQueryService} from "../media-query.service";

@Component({
  selector: 'app-panel-side',
  templateUrl: './panel-side.component.html',
  styleUrls: ['./panel-side.component.scss']
})
export class PanelSideComponent implements OnInit, DoCheck {

  @Input() panelData: any;
  @Output() closeEvent: EventEmitter<any> = new EventEmitter();
  events: string[] = [];
  mobileQuery: MediaQueryList;
  show = true;
  opened: boolean = this.sideBarService.getSideBarState() ?
    this.sideBarService.getSideBarState() : false;
  devices: any;
  margin: string;
  openedPreviewState: boolean;
  start: number;
  single: any[];
  multi: any[];
  duo: any[];
  cards = [ { name: 'Empreendimentos', number: 5 },
    { name: 'Projetos', number: 7 },
    { name: 'Nº de estudos em andamento', number: 0 },
    { name: 'Licenças vigentes', number: 6 },
    { name: 'Licenças a serem renovadas', number: 5 },
    { name: 'Nº de Licenças Vencidas', number: 2 }];
  private mobileQueryListener: () => void;
  colorScheme = {
    domain: [ '#d95f02', '#1b9e77']
  };


  constructor(public sideBarService: SidebarService, private mediaQueryService: MediaQueryService ) {
    // Object.assign(this, { single, multi, duo });
    this.responsiveGrid();
  }

  ngOnInit() {
  }

  ngDoCheck() {
    if (this.openedPreviewState !== this.opened) {
      this.show = false;
      setTimeout(() => {
        this.show = true;
      }, 300);
    }
  }

  responsiveGrid() {
    const chartGrid = new Map([
      ['xs', 1],
      ['sm', 1],
      ['md', 1],
      ['lg', 2],
      ['xl', 2]
    ]);
    this.mediaQueryService.setMediQueryGrid(chartGrid, this.start);
    // this.chartGridCols = this.mediaQueryService.setGridMedia(chartGrid, this.start);
  }

  closePanel() {
    console.log('emit event');
    this.closeEvent.emit({opened: false});
  }

}
