import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root'
})

export class ProjectService {
  private baseUrl: string = "";

  constructor(
    private http: HttpClient
  ) {
    this.baseUrl = environment.api;
  }

  private setHeader(withCredentials: boolean) {
    return {
      headers: new HttpHeaders({
        'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
        'Cache-Control': 'no-cache',
        'accept': '*/*',
        'Content-Type': 'application/json'
      }),
    };
  }

  listProject(): Observable<any> {
    return this.http.get(`${this.baseUrl}project/all?page=0&size=50&companyId=${localStorage.getItem('companyId')}`, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro:', error);
          return of([]);
        })
      );
  }

  listProjectUUID(uuid): Observable<any> {
    return this.http.get(`${this.baseUrl}project/project/${uuid}`, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro:', error);
          return of([]);
        })
      );
  }

  listPermitionUUID(uuid): Observable<any> {
    return this.http.get(`${this.baseUrl}permition/${uuid}`, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro:', error);
          return of([]);
        })
      );
  }

  listProgramUUID(uuid): Observable<any> {
    return this.http.get(`${this.baseUrl}program/${uuid}`, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro:', error);
          return of([]);
        })
      );
  }

  dynamicPermition(): Observable<any> {
    return this.http.get(`${this.baseUrl}mongo/dynamic-form?companyId=${localStorage.getItem('companyId')}&type=Permition`, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro:', error);
          return of([]);
        })
      );
  }

  createDynamicFormAnswers(dados): Observable<any> {

    return this.http.post(`${this.baseUrl}mongo/create-dynamic-form-answers`, dados, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro:', error);
          return of([]);
        })
      );
  }

  listUserCompany(): Observable<any> {

    return this.http.get(`${this.baseUrl}user/all-users-company?page=0&size=50&companyId=${localStorage.getItem('companyId')}`, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro ao listar usuarios:', error);
          return of([]);
        })
      );

  }

  addPermition(dados): Observable<any> {
    return this.http.post(`${this.baseUrl}permition`, dados,  this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro ao cadastrar Permissão:', error);
          return of([]);
        })
      );

  }

  editPermition(dados): Observable<any> {
    return this.http.put(`${this.baseUrl}permition`, dados,  this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro ao Editar Permissão:', error);
          return of([]);
        })
      );

  }

  createFolderPermition(dados): Observable<any> {
    return this.http.post(`${this.baseUrl}project/create-folder`, dados, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro:', error);
          return of([]);
        })
      );
  }




}
