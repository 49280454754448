import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { MiniSidenavComponent } from './components/mini-sidenav/mini-sidenav.component';
import { SideMenuComponent } from './components/side-menu/side-menu.component';
import {SidebarService} from "./sidebar.service";
import {SharedModule} from "../shared/shared.module";
import { LayoutComponent } from './layout/layout.component';
import {MatSidenavModule} from "@angular/material/sidenav";
import {RouterModule} from "@angular/router";
import { CompanyDialogComponent } from './components/company-dialog/company-dialog.component';



@NgModule({
  declarations: [NavBarComponent, MiniSidenavComponent, SideMenuComponent, LayoutComponent, CompanyDialogComponent],
  imports: [
    CommonModule,
    SharedModule,
    MatSidenavModule,
    RouterModule
  ],
  providers: [ SidebarService],
  exports: [ NavBarComponent, MiniSidenavComponent, SideMenuComponent ],
})
export class CoreModule { }
