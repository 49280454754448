import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { LoginModule } from './login/login.module';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { CoreModule} from "./core/core.module";
import { SharedModule } from './shared/shared.module';
import { MatSidenavModule} from "@angular/material/sidenav";
import { MatPaginatorIntl} from "@angular/material/paginator";
import { CustomPaginator} from "./shared/custom-paginator-config";
import { FullCalendarModule} from "@fullcalendar/angular";
import { LogoutComponent } from './auth/logout/logout.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [
    AppComponent,
    LogoutComponent,
    NotFoundComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    LoginModule,
    AngularFontAwesomeModule,
    CoreModule,
    SharedModule,
    MatSidenavModule,
    MatSelectModule,
    MatTableModule,
    MatMenuModule,
    MatIconModule,
    MatPaginatorModule,
    MatSortModule,
    MatDialogModule,
    FormsModule,
    FullCalendarModule
  ],
  providers: [
    { provide: MatPaginatorIntl,
      useValue: CustomPaginator()

    } ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }
