import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-remove-account-dialog',
  templateUrl: './remove-account-dialog.component.html',
  styleUrls: ['./remove-account-dialog.component.scss']
})
export class RemoveAccountDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<RemoveAccountDialogComponent>) { }

  ngOnInit() {
  }

  closeDialog(confirmation: boolean) {
    this.dialogRef.close(confirmation);
  }
}
