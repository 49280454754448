import { Component, OnInit, Input, TemplateRef, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-tab-content',
  templateUrl: './tab-content.component.html',
  styleUrls: ['./tab-content.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TabContentComponent implements OnInit {
  @Input() contentPanel: any;
  @Input() contentType: string;
  @Input() template: TemplateRef<any>;
  @Input() context: any;
  dataSource = [];

  constructor() { }

  ngOnInit() {
      if (this.contentPanel && Array.isArray(this.contentPanel)) {
        this.contentPanel.forEach((element) => {
          if (element.tab === this.context) {
            this.dataSource.push(element.context);
          }
        });
      } else {
        console.error('contentPanel não está definido ou não é um array');
      }
  }

  legendColor(legend: string) {
    switch (legend) {
      case 'Não cumprida':
        return 'alert-icon';
      case 'Em cumprimento, atrasada':
        return 'late-icon';
      case 'Em cumprimento, em dia':
        return 'fine-icon';
      case 'Recorrente':
        return 'loop-icon';
      case 'Aviso de alterações':
        return 'alteration-icon';
      case 'Cumprida':
        return 'finished-icon';
      case 'Cumprida com atraso':
        return 'late-finished-icon';
    }
  }

}
