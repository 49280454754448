import { Component, OnInit } from '@angular/core';
import { SharedService } from '../shared/service/shared.service';
import { AddCompanyComponent } from './dialog/add-company-component'
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})
export class CompanyComponent implements OnInit {
  planos = [
    { nome: 'Básico', detalhes: ['Até 3 unidades operacionais (CNPJs)', 'Até 3 usuários'], preco: '700/mês', descricao: 'Perfeito para pequenas e médias empresas.' },
    { nome: 'Premium', detalhes: ['Até 7 unidades operacionais (CNPJs)', 'Até 10 usuários'], preco: '1.300/mês', descricao: 'Perfeito para empresas que gerenciam muitas licenças.' },
  ];

  isLoadingUsers = false;
  modalAberto = false;
  planoSelecionado: string | null = null;
  dataSource = [];
  constructor(
    private service: SharedService,
    private dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.loadPrice();
  }

  loadPrice(): void {
    this.service.listPrice().subscribe(
      (response: any) => {
        this.dataSource = response;
      },
      (error) => {
        console.log("ERRO")

      }
    );
  }

  translatefreq(dados: string): string {
    const dadosMap: { [key: string]: string } = {
      'MONTHLY': 'Mês',
      'ANUAL': 'Anual',
    };
    return dadosMap[dados] || dados;
  }

  openListPriceDialog(id: any, price: any, frequencyPlan: any): void {
    console.log(id, price, frequencyPlan)
    const dialogRef = this.dialog.open(AddCompanyComponent, {
      width: '60%',
      height: 'auto',
      panelClass: 'dialog-container',
      data: { id: id, price: price, frequencyPlan: frequencyPlan }
    });

    dialogRef.afterClosed().subscribe(() => {
      // Implementação após fechamento do dialog
    });
  }

  fecharModal() {
    this.modalAberto = false;
  }
}
