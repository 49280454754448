import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-responsible-dialog',
  templateUrl: './responsible-dialog.component.html',
  styleUrls: ['./responsible-dialog.component.scss']
})
export class ResponsibleDialogComponent implements OnInit {
  folders = [
    {
      name: 'Photos',
    },
    {
      name: 'Recipes',
    },
    {
      name: 'Work',
    },
    {
      name: 'Work',
    }
  ];
  notes = [
    {
      name: 'Vacation Itinerary',
    },
    {
      name: 'Kitchen Remodel',
    }
  ];
  constructor(public dialogRef: MatDialogRef<ResponsibleDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() { }

  closeDialog() {
    this.dialogRef.close();
  }

  setColors(index: number) {
    if (index > 0 && index <= 4) {
      return 'figure-' + index;
    } else if (index % 4 !== 0) {
      return 'figure-' + index % 4;
    } else {
      return 'figure-4';
    }
  }

}
