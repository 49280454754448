import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SidebarService } from "../../sidebar.service";
import { SharedService } from '../../../shared/service/shared.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { first } from 'rxjs/operators';
@Component({
  selector: 'app-mini-sidenav',
  templateUrl: './mini-sidenav.component.html',
  styleUrls: ['./mini-sidenav.component.scss']
})
export class MiniSidenavComponent implements OnInit {

  constructor(
    private sideBarService: SidebarService,
    private router: Router,
    private service: SharedService,
    private snackBar: MatSnackBar,
  ) { }

  seeMoreOperationalUnit: any;
  seeMoreProject: any;
  seeMorePermition: any;
  operationalUnits: any[] = [];
  projectsAll: any[] = [];
  enterprise: any[] = [];
  permitionAll: any[] = [];
  showMenu: boolean = false;

  ngOnInit() {

    let profilesId = localStorage.getItem('profilesId');

    let currentCompanyId;
    if (localStorage.getItem('companyId') != null) {
      currentCompanyId = parseInt(localStorage.getItem('companyId') || '');
    } else {
      currentCompanyId = localStorage.getItem('companies')[0];
    }
    let companies = JSON.parse(localStorage.getItem('companies') || '[]');
    if (companies && Array.isArray(companies)) {
      let company = companies.find((item: any) => item.companyId === currentCompanyId);     
      if (company) {
        localStorage.setItem('profilesId', company.profilesId.toString());
        if (company.profilesId == 6) {
          location.href = 'users-list';
        }
      }
    }

    this.showMenu = profilesId && parseInt(profilesId, 10) != 6;

    this.loadListMenu();    
  }

  sideMenuToggle() {
    this.sideBarService.toggle();
  }

  loadListMenu() {
    this.service.menus().subscribe((response: any) => {
   
      if (response) {
        this.operationalUnits = response.operationalUnits.slice(0, 10);
        this.projectsAll = response.projects.slice(0, 10);
        this.permitionAll = response.permitions.slice(0, 10);
  
        this.seeMoreOperationalUnit = response.seeMoreOperationalUnit;
        this.seeMoreProject = response.seeMoreProject;
        this.seeMorePermition = response.seeMorePermition;
      } else {
        this.snackBar.open('Nenhuma lista do menu encontrada', 'Fechar', {
          duration: 1500,
          panelClass: ['snack-bar-success']
        });
      }
    }, (error) => {
      this.snackBar.open('Erro ao carregar lista de menus', 'Fechar', {
        duration: 1500,
        panelClass: ['snack-bar-success']
      });
    });
  }

  /*loadEnterprise() {
    this.serviceEnterprise.operationalUnitsUUID(this.id).subscribe((response: any) => {
      if (response && response.operationalUnit) {

        this.enterprise = [
          {

            id: operationalUnit.id || '',
            name: operationalUnit.name || ''
          }
        ];

        if (this.paginator) {
          this.dataSource.paginator = this.paginator;
        }
        if (this.sort) {
          this.dataSource.sort = this.sort;
        }

      } else {
       this.snackBar.open('Nenhuma unidade operacional encontrada', 'Fechar', {
        duration: 1500,
        panelClass: ['snack-bar-success']
      });
      }

    }, (error) => {
       this.snackBar.open('Erro ao carregar unidades operacionais', 'Fechar', {
        duration: 1500,
        panelClass: ['snack-bar-success']
      });
    });
  }
    */

  changeRoute(route: string) {

    this.router.navigate([route]).then(success => {
      if (success) {
        console.log('Navigation is successful!');
      } else {
        console.log('Navigation has failed!');
      }
    });
  }

 
  
  showMessage(message: string, action: string = 'Fechar') {
    this.snackBar.open(message, action, {
      duration: 3000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      panelClass: ['custom-snackbar']
    });
  }

}
