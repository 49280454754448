import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { of, Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})

export class SharedService {
  private baseUrl: string = environment.api;

  constructor(private http: HttpClient) { }

  private setHeader(withCredentials: boolean) {
    return {
      headers: new HttpHeaders({
        'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
        'Cache-Control': 'no-cache',
        'Accept': '*/*',
        'Content-Type': 'application/json'
      }),
    };
  }

  // Login
  login(email: string, password: string): Observable<any> {
    const dados = {
      email: email,
      password: password
    };
    return this.http.post<any>(
      `${this.baseUrl}login`,
      {},
      { ...this.setHeader(true), observe: 'response' }
    ).pipe(
      catchError((error) => {
        console.error('Erro:', error);
        return of(error);
      })
    );
  }

  operationalUnitsAll(): Observable<any> {
    return this.http.get(`${this.baseUrl}operational-units/all?companyId=${localStorage.getItem('companyId')}`, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro ao listar unidades operacionais:', error);
          return of([]);
        })
      );
  }

  addUnit(dados): Observable<any> {
    return this.http.post<any>(
      `${this.baseUrl}operational-units`,
      dados,
      { ...this.setHeader(true), observe: 'response' }
    ).pipe(
      catchError((error) => {
        console.error('Erro:', error);
        return of(error);
      })
    );
  }

  listUserCompany(): Observable<any> {
    return this.http.get(`${this.baseUrl}user/all-users-company?page=0&size=50&companyId=${localStorage.getItem('companyId')}`, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro ao listar usuarios:', error);
          return of([]);
        })
      );
  }

  listUserCompanyPage(page: number, size: number): Observable<any> {
    return this.http.get(`${this.baseUrl}user/all-users-company?page=${page}&size=${size}&companyId=${localStorage.getItem('companyId')}`, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro ao listar usuarios:', error);
          return of([]);
        })
      );
  }

  addOperational(unit: any): Observable<any> {
    return this.http.post<any>(
      `${this.baseUrl}operational-units`,
      unit,
      { ...this.setHeader(true), observe: 'response' }
    ).pipe(
      catchError((error) => {
        console.error('Erro:', error);
        return of(error);
      })
    );
  }

  listDataDynamicForms(id): Observable<any> {

    return this.http.get(`${this.baseUrl}operational-units/${id}`, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro:', error);
          return of([]);
        })
      );
  }

  listDynamicForms(companyId): Observable<any> {
    return this.http.get(`${this.baseUrl}mongo/dynamic-form?companyId=${companyId}&type=OperationalUnit`, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro:', error);
          return of([]);
        })
      );
  }

  editOperational(company: string, operationalUnitId: string, name: string, detail: string, responsibleId: number): Observable<any> {
    const operational = {
      company: company,
      operationalUnitId: operationalUnitId,
      name: name,
      detail: detail,
      responsibleId: responsibleId,
    };

    return this.http.put<any>(
      `${this.baseUrl}operational-units`, operational,
      { ...this.setHeader(true), observe: 'response' }
    ).pipe(
      catchError((error) => {
        console.error('Erro:', error);
        return of(error);
      })
    );
  }

  removeOperational(unitId: string): Observable<any> {
    const url = `${this.baseUrl}operational-units/remove/${unitId}`;

    const headers = this.setHeader(true).headers;

    return this.http
      .delete(url, { headers })
      .pipe(
        catchError((error) => {
          console.error('Erro ao remover unidade operacional:', error);
          return throwError(error);
        })
      );
  }

  removeSupplier(supplierId: string): Observable<any> {
    const url = `${this.baseUrl}supplier/${supplierId}`;
    const headers = this.setHeader(true).headers;

    return this.http
      .delete(url, { headers })
      .pipe(
        catchError((error) => {
          console.error('Erro ao remover Fornecedor:', error);
          return throwError(error);
        })
      );
  }

  removeUserOperational(userId: number): Observable<any> {
    const url = `${this.baseUrl}user/user-company`;

    const headers = this.setHeader(true).headers;

    const body = {
      userId: userId,
      companyId: localStorage.getItem('companyId')
    };

    return this.http
      .request('DELETE', url, { headers, body })
      .pipe(
        catchError((error) => {
          console.error('Erro ao remover unidade operacional:', error);
          return throwError(error);
        })
      );
  }

  addOwnerOperationalUnits(userId: any, operationalUnitId: any) {
    const projectData = {
      companyId: localStorage.getItem('companyId'),
      userId: userId,
      operationalUnitId: operationalUnitId,
    };
    return this.http.post<any>(
      `${this.baseUrl}operational-units/add-owner`, projectData,
      { ...this.setHeader(true), observe: 'response' }
    ).pipe(
      catchError((error) => {
        console.error('Erro:', error);
        return of(error);
      })
    );
  }

  listremoveUserCompany(companyId: any) {
    return this.http.get(`${this.baseUrl}user/all-users-company?page=0&size=10&companyId=${companyId}`, this.setHeader(true)).pipe(
      catchError((error) => {
        console.error('Erro ao Listar Usuarios', error);
        return of(null);
      })
    );
  }

  // Campos Dinamicos
 

  dynamicCommitment(companyId): Observable<any> {
    return this.http.get(`${this.baseUrl}mongo/dynamic-form?companyId=${companyId}&type=Commitment`, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro:', error);
          return of([]);
        })
      );
  }

  dynamicContract(companyId): Observable<any> {
    return this.http.get(`${this.baseUrl}mongo/dynamic-form?companyId=${companyId}&type=Contract`, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro:', error);
          return of([]);
        })
      );
  }

  dynamicAdditionalInformation(companyId): Observable<any> {
    return this.http.get(`${this.baseUrl}mongo/dynamic-form?companyId=${companyId}&type=AdditionalInformation`, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro:', error);
          return of([]);
        })
      );
  }

  dynamicGetOperationalUnit(companyId): Observable<any> {
    return this.http.get(`${this.baseUrl}mongo/dynamic-form?companyId=${companyId}&type=OperationalUnit`, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro:', error);
          return of([]);
        })
      );
  }

  dynamicSupplier(companyId): Observable<any> {
    return this.http.get(`${this.baseUrl}mongo/dynamic-form?companyId=${companyId}&type=Supplier`, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro:', error);
          return of([]);
        })
      );
  }

  dynamicProject(companyId): Observable<any> {
    return this.http.get(`${this.baseUrl}mongo/dynamic-form?companyId=${companyId}&type=Project`, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro:', error);
          return of([]);
        })
      );
  }

  listActivity(): Observable<any> {
    return this.http.get(`${this.baseUrl}activity?companyId=${localStorage.getItem('companyId')}`, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro:', error);
          return of([]);
        })
      );
  }

  MylistActivity(): Observable<any> {
    return this.http.get(`${this.baseUrl}activity/my-activities?companyId=${localStorage.getItem('companyId')}`, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro:', error);
          return of([]);
        })
      );
  }


  AddActivity(dados): Observable<any> {
    return this.http.post<any>(
      `${this.baseUrl}activity`, dados,
      { ...this.setHeader(true), observe: 'response' }
    ).pipe(
      catchError((error) => {
        console.error('Erro:', error);
        return of(error);
      })
    );
  }

  editActivity(dados): Observable<any> {
    return this.http.put<any>(
      `${this.baseUrl}activity`, dados,
      { ...this.setHeader(true), observe: 'response' }
    ).pipe(
      catchError((error) => {
        console.error('Erro:', error);
        return of(error);
      })
    );
  }

  moveActivity(uuid: string, status: string): Observable<any> {
    const companyId = localStorage.getItem('companyId');
    return this.http.put<any>(
      `${this.baseUrl}activity/move/${uuid}?companyId=${companyId}&status=${status}`, {},
      { ...this.setHeader(true), observe: 'response' }
    ).pipe(
      catchError((error) => {
        console.error('Erro:', error);
        return of(error);
      })
    );
  }

  // Information Aditional
  listAdditionalAll(page: number = 0, size: number = 50): Observable<any> {
    return this.http.get(`${this.baseUrl}additional-information?page=${page}&size=${size}&companyId=${localStorage.getItem('companyId')}`, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro:', error);
          return of([]);
        })
      );
  }

  editAdditionalInformation(dados): Observable<any> {
    return this.http.put<any>(
      `${this.baseUrl}additional-information`, dados,
      { ...this.setHeader(true), observe: 'response' }
    ).pipe(
      catchError((error) => {
        console.error('Erro:', error);
        return of(error);
      })
    );
  }

  addAdditionalInformation(dados): Observable<any> {
    return this.http.post<any>(
      `${this.baseUrl}additional-information`, dados,
      { ...this.setHeader(true), observe: 'response' }
    ).pipe(
      catchError((error) => {
        console.error('Erro:', error);
        return of(error);
      })
    );
  }

  dynamicAdditional(): Observable<any> {
    return this.http.get(`${this.baseUrl}mongo/dynamic-form?companyId=${localStorage.getItem('companyId')}&type=Aditional`, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro:', error);
          return of([]);
        })
      );
  }

  // Commitment

  listCommitmentAll(page: number = 0, size: number = 50): Observable<any> {
    return this.http.get(`${this.baseUrl}commitments?page=${page}&size=${size}&companyId=${localStorage.getItem('companyId')}`, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro:', error);
          return of([]);
        })
      );
  }

  editCommitment(dados: any): Observable<any> {
    return this.http.put<any>(
      `${this.baseUrl}commitments`, dados,
      { ...this.setHeader(true), observe: 'response' }
    ).pipe(
      catchError((error) => {
        console.error('Erro:', error);
        return of(error);
      })
    );
  }

  addCommitment(dados: any): Observable<any> {
    return this.http.post<any>(
      `${this.baseUrl}commitments`, dados,
      { ...this.setHeader(true), observe: 'response' }
    ).pipe(
      catchError((error) => {
        console.error('Erro:', error);
        return of(error);
      })
    );
  }

  //Constraint
  addConstraint(dados): Observable<any> {
    return this.http.post<any>(
      `${this.baseUrl}constraint`, dados,
      { ...this.setHeader(true), observe: 'response' }
    ).pipe(
      catchError((error) => {
        console.error('Erro:', error);
        return of(error);
      })
    );
  }

  createDynamicFormAnswers(dados): Observable<any> {
    return this.http.post<any>(
      `${this.baseUrl}mongo/create-dynamic-form-answers`, dados,
      { ...this.setHeader(true), observe: 'response' }
    ).pipe(
      catchError((error) => {
        console.error('Erro:', error);
        return of(error);
      })
    );
  }

  listConstraintUUID(uuid): Observable<any> {
    return this.http.get(`${this.baseUrl}constraint/${uuid}`, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro:', error);
          return of([]);
        })
      );
  }

  editConstraint(dados): Observable<any> {
    return this.http.put<any>(
      `${this.baseUrl}constraint`, dados,
      { ...this.setHeader(true), observe: 'response' }
    ).pipe(
      catchError((error) => {
        console.error('Erro:', error);
        return of(error);
      })
    );
  }

  dynamicConstraint(): Observable<any> {
    return this.http.get(`${this.baseUrl}mongo/dynamic-form?companyId=${localStorage.getItem('companyId')}&type=Constraint`, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro:', error);
          return of([]);
        })
      );
  }

  listConstraint(page: number = 0, size: number = 50): Observable<any> {
    return this.http.get(`${this.baseUrl}constraint?page=${page}&size=${size}&companyId=${localStorage.getItem('companyId')}`, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro:', error);
          return of([]);
        })
      );
  }

  //Constraint
  listContractAll(): Observable<any> {
    return this.http.get(`${this.baseUrl}contract?page=0&size=50&companyId=${localStorage.getItem('companyId')}`, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro ao listar Contratos:', error);
          return of([]);
        })
      );
  }

  // Dashboard
  getPowerBiEmbedToken(userId: string): Observable<any> {
    return this.http
      .post(
        `${this.baseUrl}get-embed-token?userId=${userId}`,
        {},
        this.setHeader(true)
      )
      .pipe(
        catchError((error) => {
          console.error('Erro ao obter token Power BI:', error);
          return of(null);
        })
      );
  }

  // Enterprise
  deleteUser(dados: { companyId: string; userId: string; operationalUnitId: string }): Observable<any> {
    const url = `${this.baseUrl}operational-units/remove-owner`;
    const body = {
      companyId: dados.companyId,
      userId: dados.userId,
      operationalUnitId: dados.operationalUnitId,
    };

    const headers = this.setHeader(true).headers;
    return this.http
      .request('DELETE', url, {
        headers,
        body,
        observe: 'response',
      })
      .pipe(
        catchError((error) => {
          console.error('Erro ao remover usuário:', error);
          return throwError(error);
        })
      );
  }

  operationalUnitsUUID(id): Observable<any> {
    return this.http.get(`${this.baseUrl}operational-units/${id}`, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro:', error);
          return of([]);
        })
      );
  }

  addProject(companyId: string, operationalUnit: string, name: string, phase: string, observation: string, responsibleId: string): Observable<any> {
    const projectData = {
      projectId: '',
      companyId: companyId,
      operationalUnit: operationalUnit,
      name: name,
      phase: phase,
      observation: observation,
      responsibleId: responsibleId
    };

    return this.http.post<any>(
      `${this.baseUrl}project`, projectData,
      { ...this.setHeader(true), observe: 'response' }
    ).pipe(
      catchError((error) => {
        console.error('Erro:', error);
        return of(error);
      })
    );
  }

  editProject(projectId: string, companyId: string, name: string, phase: string, observation: string, responsibleId: string): Observable<any> {
    const projectData = {
      projectId: projectId,
      companyId: companyId,
      operationalUnit: projectId,
      name: name,
      phase: phase,
      observation: observation,
      responsibleId: responsibleId
    };

    return this.http.put<any>(
      `${this.baseUrl}project`, projectData,
      { ...this.setHeader(true), observe: 'response' }
    ).pipe(
      catchError((error) => {
        console.error('Erro:', error);
        return of(error);
      })
    );
  }

  addOwnerProject(idsOwners: any[], projectId: any) {
    const projectData = {
      companyId: localStorage.getItem('companyId'),
      usersId: idsOwners,
      projectId: projectId,
    };
    return this.http.post<any>(
      `${this.baseUrl}project/add-owner`, projectData,
      { ...this.setHeader(true), observe: 'response' }
    ).pipe(
      catchError((error) => {
        console.error('Erro:', error);
        return of(error);
      })
    );
  }

  removeOwner(idsOwners: any, projectId: any) {
    const url = `${this.baseUrl}project/remove-owner`;
    const headers = this.setHeader(true).headers;
    const body = {
      companyId: localStorage.getItem('companyId'),
      usersId: [idsOwners],
      projectId: projectId,
    };

    return this.http
      .request('DELETE', url, { headers, body })
      .pipe(
        catchError((error) => {
          console.error('Erro ao remover unidade operacional:', error);
          return throwError(error);
        })
      );
  }

  dynamicOperationalUnit(id): Observable<any> {
    return this.http.get(`${this.baseUrl}operational-units/${id}`, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro:', error);
          return of([]);
        })
      );
  }

  listDynamicDataProjetct(projectId): Observable<any> {
    return this.http.get(`${this.baseUrl}mongo/dynamic-data?type=PROJECT&staticAnswers=${projectId}`, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro:', error);
          return of([]);
        })
      );
  }

  listDynamicFormConstraint(companyId): Observable<any> {
    return this.http.get(`${this.baseUrl}mongo/dynamic-form?companyId=${companyId}&type=Constraint`, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro:', error);
          return of([]);
        })
      );
  }

  listDynamicFormProject(companyId): Observable<any> {
    return this.http.get(`${this.baseUrl}mongo/dynamic-form?companyId=${companyId}&type=Project`, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro:', error);
          return of([]);
        })
      );
  }

  listDynamicForm(companyId): Observable<any> {
    return this.http.get(`${this.baseUrl}mongo/dynamic-form?companyId=${companyId}&type=PROJECT`, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro:', error);
          return of([]);
        })
      );
  }

  createFolderUnit(dados): Observable<any> {
    return this.http.post<any>(
      `${this.baseUrl}operational-units/create-folder`, dados,
      { ...this.setHeader(true), observe: 'response' }
    ).pipe(
      catchError((error) => {
        console.error('Erro:', error);
        return of(error);
      })
    );
  }

  addFile(data: FormData | any, isFile: boolean, url): Observable<any> {
    let headers = new HttpHeaders({
      'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
    });  
    
    if (!isFile) {
      headers = headers.set('Content-Type', 'application/json');
    }
  
    return this.http.post<any>(
      `${this.baseUrl}${url}`,
      data,
      { headers, observe: 'response' }
    ).pipe(
      catchError((error) => {
        console.error('Erro:', error);
        return of(error);
      })
    );
  }

  menus(): Observable<any> {
    const url = `${this.baseUrl}menus?companyId=${localStorage.getItem('companyId')}`;
    return this.http.get(url, this.setHeader(true)).pipe(
      catchError((error) => {
        console.error('Erro:', error);
        return of([]);
      })
    );
  }

  // Hierarchy 
  listHierarchy(): Observable<any> {
    return this.http.get(`${this.baseUrl}menus/hierarchy?companyId=${localStorage.getItem('companyId')}`, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro ao listar Hierarquia:', error);
          return of([]);
        })
      );
  }

  // Permition 
  allPermition(): Observable<any> {
    const url = `${this.baseUrl}permition?page=0&size=50&companyId=${localStorage.getItem('companyId')}`;
    return this.http.get(url, this.setHeader(true)).pipe(
      catchError((error) => {
        console.error('Erro:', error);
        return of([]);
      })
    );
  }

  listPermitionAll(page: number = 0, size: number = 50): Observable<any> {
    return this.http.get(`${this.baseUrl}permition?page=${page}&size=${size}&companyId=${localStorage.getItem('companyId')}`, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro:', error);
          return of([]);
        })
      );
  }

  listDynamicDataPermition(projectId): Observable<any> {
    return this.http.get(`${this.baseUrl}mongo/dynamic-data?type=PERMITION&staticAnswers=${projectId}`, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro:', error);
          return of([]);
        })
      );
  }

  addPermition(dados): Observable<any> {
    return this.http.post<any>(
      `${this.baseUrl}permition`, dados,
      { ...this.setHeader(true), observe: 'response' }
    ).pipe(
      catchError((error) => {
        console.error('Erro:', error);
        return of(error);
      })
    );
  }

  createFolderProject(dados): Observable<any> {
    return this.http.post<any>(
      `${this.baseUrl}project/create-folder`, dados,
      { ...this.setHeader(true), observe: 'response' }
    ).pipe(
      catchError((error) => {
        console.error('Erro:', error);
        return of(error);
      })
    );
  }

  editPermition(dados): Observable<any> {
    return this.http.put<any>(
      `${this.baseUrl}permition`, dados,
      { ...this.setHeader(true), observe: 'response' }
    ).pipe(
      catchError((error) => {
        console.error('Erro:', error);
        return of(error);
      })
    );
  }

  listDynamicPermition(id): Observable<any> {

    return this.http.get(`${this.baseUrl}mongo/dynamic-data?type=PERMITION&staticAnswers=${id}`, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro ao listar Dados Dinamicos:', error);
          return of([]);
        })
      );
  }

  addOwnerPermition(idsOwners: any[], permitionId: any) {
    const projectData = {
      companyId: localStorage.getItem('companyId'),
      usersId: idsOwners,
      permitionId: permitionId,
    };

    return this.http.post<any>(
      `${this.baseUrl}permition/add-owner`, projectData,
      { ...this.setHeader(true), observe: 'response' }
    ).pipe(
      catchError((error) => {
        console.error('Erro:', error);
        return of(error);
      })
    );
  }


  listDynamicFormPermition(companyId): Observable<any> {
    return this.http.get(`${this.baseUrl}mongo/dynamic-form?companyId=${companyId}&type=Permition`, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro:', error);
          return of([]);
        })
      );
  }

  dynamicPermition(): Observable<any> {
    return this.http.get(`${this.baseUrl}mongo/dynamic-form?companyId=${localStorage.getItem('companyId')}&type=Permition`, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro:', error);
          return of([]);
        })
      );
  }

  listPermition(id): Observable<any> {
    return this.http.get(`${this.baseUrl}permition/${id}`, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro:', error);
          return of([]);
        })
      );
  }

  listAddProgram(dados): Observable<any> {
    return this.http.post<any>(
      `${this.baseUrl}program`, dados,
      { ...this.setHeader(true), observe: 'response' }
    ).pipe(
      catchError((error) => {
        console.error('Erro:', error);
        return of(error);
      })
    );
  }

  listProgramUUID(uuid): Observable<any> {
    return this.http.get(`${this.baseUrl}program/${uuid}`, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro:', error);
          return of([]);
        })
      );
  }

  listProjectUUID(uuid): Observable<any> {
    return this.http.get(`${this.baseUrl}project/project/${uuid}`, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro:', error);
          return of([]);
        })
      );
  }


  dynamicProgram(): Observable<any> {
    return this.http.get(`${this.baseUrl}mongo/dynamic-form?companyId=${localStorage.getItem('companyId')}&type=Program`, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro:', error);
          return of([]);
        })
      );
  }

  createFolderPermition(dados): Observable<any> {
    return this.http.post<any>(
      `${this.baseUrl}permition/create-folder`, dados,
      { ...this.setHeader(true), observe: 'response' }
    ).pipe(
      catchError((error) => {
        console.error('Erro:', error);
        return of(error);
      })
    );
  }

  changeProject(dados): Observable<any> {
    return this.http.post<any>(
      `${this.baseUrl}permition/change-project`, dados,
      { ...this.setHeader(true), observe: 'response' }
    ).pipe(
      catchError((error) => {
        console.error('Erro:', error);
        return of(error);
      })
    );
  }

  listProject(): Observable<any> {
    return this.http.get(`${this.baseUrl}project/all?page=0&size=50&companyId=${localStorage.getItem('companyId')}`, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro:', error);
          return of([]);
        })
      );
  }

  listProjectAll(page: number = 0, size: number = 50): Observable<any> {
    return this.http.get(`${this.baseUrl}project/all?page=${page}&size=${size}&companyId=${localStorage.getItem('companyId')}`, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro:', error);
          return of([]);
        })
      );
  }

  listProgramAll(page: number = 0, size: number = 50): Observable<any> {
    return this.http.get(`${this.baseUrl}program?page=${page}&size=${size}&companyId=${localStorage.getItem('companyId')}`, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro:', error);
          return of([]);
        })
      );
  }

  editProgram(dados: any): Observable<any> {
    return this.http.put<any>(
      `${this.baseUrl}program`, dados,
      { ...this.setHeader(true), observe: 'response' }
    ).pipe(
      catchError((error) => {
        console.error('Erro:', error);
        return of(error);
      })
    );
  }

  // Supplier
  listSuppliersAll(page: number = 0, size: number = 50): Observable<any> {
    return this.http.get(`${this.baseUrl}supplier?page=0&size=50&companyId=${localStorage.getItem('companyId')}`, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro ao listar Ponto Focal:', error);
          return of([]);
        })
      );
  }

  listSuppliers(id): Observable<any> {
    return this.http.get(`${this.baseUrl}supplier/${id}`, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro ao listar Fornecedor:', error);
          return of([]);
        })
      );
  }

  listContractSupplier(id, page: number = 0, size: number = 50): Observable<any> {
    return this.http.get(`${this.baseUrl}contract?page=${page}&size=${size}&supplierId=${id}&companyId=${localStorage.getItem('companyId')}`, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro ao listar Contratos:', error);
          return of([]);
        })
      );
  }


  listSellers(id, page: number = 0, size: number = 50): Observable<any> {
    return this.http.get(`${this.baseUrl}seller?page=${page}&size=${size}&supplierId=${id}&companyId=${localStorage.getItem('companyId')}`, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro:', error);
          return of([]);
        })
      );
  }



  addSupplier(dados): Observable<any> {
    return this.http.post<any>(
      `${this.baseUrl}supplier`, dados,
      { ...this.setHeader(true), observe: 'response' }
    ).pipe(
      catchError((error) => {
        console.error('Erro:', error);
        return of(error);
      })
    );
  }

  addSeller(dados): Observable<any> {
    return this.http.post<any>(
      `${this.baseUrl}seller`, dados,
      { ...this.setHeader(true), observe: 'response' }
    ).pipe(
      catchError((error) => {
        console.error('Erro:', error);
        return of(error);
      })
    );
  }

  addContract(dados): Observable<any> {
    return this.http.post<any>(
      `${this.baseUrl}contrat`, dados,
      { ...this.setHeader(true), observe: 'response' }
    ).pipe(
      catchError((error) => {
        console.error('Erro:', error);
        return of(error);
      })
    );
  }

  aditSupplier(dados): Observable<any> {
    return this.http.put<any>(
      `${this.baseUrl}supplier`, dados,
      { ...this.setHeader(true), observe: 'response' }
    ).pipe(
      catchError((error) => {
        console.error('Erro:', error);
        return of(error);
      })
    );
  }

  // user 
  listAllUser(companyId): Observable<any> {
    return this.http.get(`${this.baseUrl}user/all-users-company?page=0&size=50&companyId=${companyId}`, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro ao listar Usuarios:', error);
          return of([]);
        })
      );
  }

  updateUser(id: any, profile: any): Observable<any> {
    const dados = {
      companyId: localStorage.getItem('companyId'),
      userId: id,
      profile: profile
    };
    return this.http.post<any>(
      `${this.baseUrl}user/change-profile`, dados,
      { ...this.setHeader(true), observe: 'response' }
    ).pipe(
      catchError((error) => {
        console.error('Erro:', error);
        return of(error);
      })
    );
  }

  addUser(dados: any): Observable<any> {
    return this.http.post<any>(
      `${this.baseUrl}user/add-new-user`, dados,
      { ...this.setHeader(true), observe: 'response' }
    ).pipe(
      catchError((error) => {
        console.error('Erro:', error);
        return of(error);
      })
    );
  }

  liberarAcesso(id: number): Observable<any> {
    return this.http.post<any>(
      `${this.baseUrl}user/revoke-user/${id}`, {},
      { ...this.setHeader(true), observe: 'response' }
    ).pipe(
      catchError((error) => {
        console.error('Erro:', error);
        return of(error);
      })
    );
  }

  listResponsibility(userId): Observable<any> {
    return this.http.get(`${this.baseUrl}user/all-responsibility/${userId}?companyId=${localStorage.getItem('companyId')}`, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro:', error);
          return of([]);
        })
      );
  }

  transferResponsibility(dados: any): Observable<any> {
    return this.http.post<any>(
      `${this.baseUrl}user/change-responsibility`, dados,
      { ...this.setHeader(true), observe: 'response' }
    ).pipe(
      catchError((error) => {
        console.error('Erro:', error);
        return of(error);
      })
    );
  }



  // Service Orders
  listServiceOrdersAll(page: number = 0, size: number = 50): Observable<any> {
    //return this.http.get(`${this.baseUrl}service-orders/all?page=${page}&size=${size}&companyId=${localStorage.getItem('companyId')}`, this.setHeader(true))
    return this.http.get(`${this.baseUrl}service-orders/all?page=${page}&size=${size}&companyId=1`, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro:', error);
          return of([]);
        })
      );
  }

  listServiceOrdersConstract(page: number = 0, size: number = 50, supplierId): Observable<any> {
    //return this.http.get(`${this.baseUrl}service-orders/all?page=${page}&size=${size}&companyId=${localStorage.getItem('companyId')}`, this.setHeader(true))
    return this.http.get(`${this.baseUrl}service-orders/all?page=${page}&size=${size}&companyId=1&supplierId=${supplierId}`, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro:', error);
          return of([]);
        })
      );
  }

  createServiceOrders(dados): Observable<any> {
    return this.http.post<any>(
      `${this.baseUrl}service-orders`, dados,
      { ...this.setHeader(true), observe: 'response' }
    ).pipe(
      catchError((error) => {
        console.error('Erro:', error);
        return of(error);
      })
    );
  }

  editServiceOrders(dados): Observable<any> {
    return this.http.put<any>(
      `${this.baseUrl}service-orders`, dados,
      { ...this.setHeader(true), observe: 'response' }
    ).pipe(
      catchError((error) => {
        console.error('Erro:', error);
        return of(error);
      })
    );
  }

  // Exports
  generationExport(): Observable<HttpResponse<any>> {
    return this.http.post<any>(
      `${this.baseUrl}export?companyId=${localStorage.getItem('companyId')}`,
      {},
      { ...this.setHeader(true), observe: 'response' }
    ).pipe(
      catchError((error) => {
        console.error('Erro:', error);
        return of(error);
      })
    );
  }

  listGenerationExport(): Observable<any> {
    return this.http.get(`${this.baseUrl}export/my-files?companyId=${localStorage.getItem('companyId')}`, this.setHeader(true))
      .pipe(
        catchError((error) => {
          console.error('Erro:', error);
          return of([]);
        })
      );
  }

  getUrlExport(uuid): Observable<string> {
    return this.http.get(`${this.baseUrl}export/${uuid}`, {
      ...this.setHeader(true),
      responseType: 'text',
    }).pipe(
      catchError((error) => {
        console.error('Erro:', error);
        return of('');
      })
    );
  }

}
