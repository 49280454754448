import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SignInComponent } from './login/sign-in/sign-in.component';
import { RecoverPasswordComponent } from './login/recover-password/recover-password.component';
import { SigninSetPasswordComponent } from './login/sign-in/signin-set-password/signin-set-password.component'
import { LayoutComponent } from "./core/layout/layout.component";
import { ConstraintComponent } from "./constraint/constraint.component";
import { ActivityComponent } from "./activity/activity.component";
import { LogoutComponent } from './auth/logout/logout.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { EnterpriseComponent } from './enterprise/enterprise.component';
import { DashboardPanelComponent } from './dashboard-panel/dashboard-panel.component';
import { AuthGuard } from './auth.guard';

const routes: Routes = [
  { path: '', redirectTo: 'authorize', pathMatch: 'full' },
  { path: 'callback-sso', loadChildren: () => import('./callback/callback.module').then(m => m.CallbackModule) },
  { path: 'logout', component: LogoutComponent },
  { path: 'authorize', component: SignInComponent },
  { path: 'authorize_external', component: SignInComponent },
  { path: 'authorize/recover-password', component: RecoverPasswordComponent },
  { path: 'v1/renew-password', component: SigninSetPasswordComponent },
  { path: 'error/authorization', loadChildren: () => import('./error-page/authorization/authorization.module').then(m => m.AuthorizationModule) },
  { path: 'error/:code', loadChildren: () => import('./error-page/error-page.module').then(m => m.ErrorPageModule) },
  { path: 'error', loadChildren: () => import('./error-page/error-page.module').then(m => m.ErrorPageModule) },
  { path: 'dashboard', component: LayoutComponent, loadChildren: () => import('./dashboard-panel/dashboard-panel.module').then(m => m.DashboardPanelModule), canActivate: [AuthGuard] },
  { path: 'calendar', component: LayoutComponent, loadChildren: () => import('./calendar/calendar.module').then(m => m.CalendarModule), canActivate: [AuthGuard] },
  {
    path: 'enterprise', component: LayoutComponent, children: [
      { path: ':id', loadChildren: () => import('./enterprise/enterprise.module').then(m => m.EnterpriseModule), canActivate: [AuthGuard] },
    ]
  },
  { path: 'project/:id', component: LayoutComponent, loadChildren: () => import('./project/project.module').then(m => m.ProjectModule), canActivate: [AuthGuard] },
  { path: 'all/Project', component: LayoutComponent, loadChildren: () => import('./project/project-list/project-list.module').then(m => m.ProjectListModule), canActivate: [AuthGuard] },
  { path: 'permition/:id', component: LayoutComponent, loadChildren: () => import('./permition/permition.module').then(m => m.PermitionModule), canActivate: [AuthGuard] },
  { path: 'constraint', component: LayoutComponent, loadChildren: './constraint/constraint.module#ConstraintModule', canActivate: [AuthGuard] },
  { path: 'activity', component: LayoutComponent, loadChildren: './activity/activity.module#ActivityModule', canActivate: [AuthGuard] },
  { path: 'export', component: LayoutComponent, loadChildren: () => import('./export/list-export/list-export.module').then(m => m.ExportListModule), canActivate: [AuthGuard] },
  { path: 'explore', component: LayoutComponent, loadChildren: './explore-tree/explore-tree.module#ExploreTreeModule', canActivate: [AuthGuard] },
  { path: 'all/OperationalUnit', component: LayoutComponent, loadChildren: './operational-unit/operational-unit.module#OperationalUnitModule', canActivate: [AuthGuard] },
  { path: 'users-list', component: LayoutComponent, loadChildren: () => import('./user/user.module').then(m => m.UserModule), canActivate: [AuthGuard] },
  { path: 'user/add', component: LayoutComponent, loadChildren: () => import('./user/add-user/add-user.module').then(m => m.AddUserModule), canActivate: [AuthGuard] },
  { path: 'perfil', component: LayoutComponent, loadChildren: () => import('./perfil/perfil.module').then(m => m.PerfilModule), canActivate: [AuthGuard] },
  { path: 'all/Program', component: LayoutComponent, loadChildren: () => import('./program/program-list/program-list.module').then(m => m.ProgramListModule), canActivate: [AuthGuard] },
  { path: 'program/:id', component: LayoutComponent, loadChildren: () => import('./program/program.module').then(m => m.ProgramModule), canActivate: [AuthGuard] },
  { path: 'all/Permition', component: LayoutComponent, loadChildren: () => import('./permition/permition-list/permition-list.module').then(m => m.PermitionListModule), canActivate: [AuthGuard] },
  { path: 'all/Commitment', component: LayoutComponent, loadChildren: () => import('./commitment/commitment-list/commitment-list.module').then(m => m.CommitmentListModule), canActivate: [AuthGuard] },
  { path: 'all/AdditionalInformation', component: LayoutComponent, loadChildren: () => import('./additional-information/additional-information-list/additional-information-list.component.module').then(m => m.AdditionalInformationListModule), canActivate: [AuthGuard] },
  { path: 'all/Activity', component: LayoutComponent, loadChildren: () => import('./activity/activity-list/activity-list.component.module').then(m => m.ActivityListModule), canActivate: [AuthGuard] },
  { path: 'my-activities', component: LayoutComponent, loadChildren: () => import('./activity/my-activity/my-activity.component.module').then(m => m.MyActivityModule), canActivate: [AuthGuard] },
  { path: 'all/Constraint', component: LayoutComponent, loadChildren: () => import('./constraint/constraint-list/constraint-list.component.module').then(m => m.ConstraintListModule), canActivate: [AuthGuard] },
  { path: 'all/Contract', component: LayoutComponent, loadChildren: () => import('./contract/contract.module').then(m => m.ContractModule), canActivate: [AuthGuard] },
  { path: 'contract/:id', component: LayoutComponent, loadChildren: () => import('./contract/contract-list/contract-list.module').then(m => m.ContractListModule), canActivate: [AuthGuard] },
  { path: 'all/Suppliers', component: LayoutComponent, loadChildren: () => import('./suppliers/suppliers-list/suppliers-list.module').then(m => m.SuppliersListModule), canActivate: [AuthGuard] },
  { path: 'Suppliers/:id', component: LayoutComponent, loadChildren: () => import('./suppliers/suppliers.module').then(m => m.SuppliersModule), canActivate: [AuthGuard] },
  { path: 'all/Explore', component: LayoutComponent, loadChildren: () => import('./hierarchy/hierarchy.module').then(m => m.HierarchyModule), canActivate: [AuthGuard] },
  { path: 'service-orders', component: LayoutComponent, loadChildren: () => import('./service-orders/service-orders-list/service-orders-list.module').then(m => m.ServiceOrdersListModule), canActivate: [AuthGuard] },
  { path: '**', component: NotFoundComponent }


];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
