import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { CompanyComponent } from './company.component';
import { AddCompanyComponent } from './dialog/add-company-component';
import { SharedModule } from '../shared/shared.module';
import { MatTableModule } from '@angular/material/table';
import { HttpClientModule } from '@angular/common/http';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';
import { MatSortModule } from '@angular/material/sort';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatPaginatorModule } from '@angular/material/paginator';
import { CompanyRoutingModule } from './company.routing';

@NgModule({
  declarations: [
    CompanyComponent,
    AddCompanyComponent,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    SharedModule,
    MatTableModule,
    MatDialogModule,
    MatSelectModule,
    MatOptionModule,
    MatSortModule,
    MatSnackBarModule,
    MatPaginatorModule,
    CompanyRoutingModule,
  ],
  entryComponents: [AddCompanyComponent],
})
export class CompanyModule { }
