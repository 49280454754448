import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '../sign-in/User.model';
import { RecoverPasswordService } from '../recover-password/recover-password.service';
import { FormGroup, FormControl } from '@angular/forms';
import { timer } from 'rxjs';
import { take } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-recover-password',
  templateUrl: './recover-password.component.html',
  styleUrls: ['./recover-password.component.scss']
})
export class RecoverPasswordComponent implements OnInit {
  user: User;

  recoverPasswordFormGroup: FormGroup;
  secondsToRedirec = 5;
  token = '';

  constructor(public activatedRoute: ActivatedRoute, public router: Router, private recoverPasswordService: RecoverPasswordService) { }

  ngOnInit() {
    this.recoverPasswordFormGroup = new FormGroup({
      password: new FormControl('')
    });

    // this.user = window.history.state ? window.history.state.user : {};
    // if (this.user && this.user.email) {
    //   this.recoverPasswordService.sendRecoveryEmail(this.user.email).subscribe((data) => {
    //     console.log(data);
    //   });
    // } else {
    //   if (this.router) {
    //     this.router.navigateByUrl('authorize');
    //   }
    // }
  }

}
