import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { SignInService } from './login/sign-in/sign-in.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private signInService: SignInService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const publicRoutes = ['/explore', '/some-public-page']; // Rotas públicas
    if (publicRoutes.some((r) => state.url.startsWith(r))) {
      return true;
    }

    if (this.signInService.isAuthenticated()) {
      return true;
    } else {
      this.router.navigate(['/authorize']);
      return false;
    }
  }

}
