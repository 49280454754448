import {Component, OnInit, ViewChild, ChangeDetectorRef, OnDestroy} from '@angular/core';
import {SidebarService} from "../sidebar.service";
import {MediaMatcher} from "@angular/cdk/layout";

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit, OnDestroy {

  // @ts-ignore
  @ViewChild('sidenav') public sidenav;
  events: string[] = [];
  mobileQuery: MediaQueryList;
  opened = this.sideBarService.getSideBarState() ? this.sideBarService.getSideBarState() : false;
  private mobileQueryListener: () => void;

  constructor(public sideBarService: SidebarService, changeDetectorRef: ChangeDetectorRef, media: MediaMatcher) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this.mobileQueryListener = () => changeDetectorRef.detectChanges();
    if (this.mobileQuery.matches) {
      this.opened = false;
      localStorage.setItem('sidenav-state', 'false');
    }
  }

  ngOnInit() {
    this.sideBarService.setSidenav(this.sidenav);
  }

  ngOnDestroy() {
    this.sideBarService.menuState.unsubscribe();
  }
}
