export const ELEMENT_DATA = [
  {
    context: {
      name: 'Orgãos Ambientais',
      class: 'default'
    },
    tab: 'Órgãos Ambientais',
    displayedColumns: ['select', 'Orgão', 'Responsáveis', 'Pendências', 'Ações'],
    mobileDisplayedColumns: ['Orgão', 'Responsáveis', 'Ações'],
    data: [
      {number: 1, name: ' Instituto Brasileiro do Meio Ambiente e dos Recursos Naturais Renováveis (IBAMA) ',
        pendencias: '6', responsaveis: 'Alex'},
    ]
  },
  {
    context: {
      name: 'Orgãos Intervenientes',
      class: 'default'
    },
    tab: 'Órgãos Ambientais',
    displayedColumns: ['select', 'Orgão', 'Responsáveis', 'Pendências', 'Ações'],
    mobileDisplayedColumns: ['Orgão', 'Responsáveis', 'Ações'],
    data: [
      { number: 1, name: 'IPHAN', pendencias: '6', responsaveis: 'Marina' },
    ]
  }
];
