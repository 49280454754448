import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map, catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class RecoverPasswordService {
  constructor(private http: HttpClient,
    private snackBar: MatSnackBar,
  ) { }

  // Função para validar a senha
  validatePassword(password: string): boolean {
    // Expressão regular que verifica se a senha tem pelo menos uma letra e um caractere especial
    const regex = /^(?=.*[a-zA-Z])(?=.*[!@#$%^&*(),.?":{}|<>]).+$/;
    return regex.test(password);
  }

  sendRecoveryEmail(email: string, recaptchaToken: string): Observable<any> {
    return this.http.post<any>(
      `${environment.api}user/user-renew?email=${email}&recaptchaToken=true`,
      {},
      { observe: 'response' }
    ).pipe(
      catchError((error) => {
        this.snackBar.open('Erro ao enviar e-mail de recuperação', 'Fechar', {
          duration: 1500,
          panelClass: ['snack-bar-success']
        });
        return of(error);
      })
    );
  }


  updatePassword(dados: any): Observable<any> {
    const url = `${environment.api}user/user-renew`;
    const headers = { 'Content-Type': 'application/json' };

    return this.http.post(url, dados, { headers, responseType: 'text' }).pipe(
      map((response: string) => {

        return { url: response };
      }),
      catchError((error) => {
        this.snackBar.open('Erro ao enviar email', 'Fechar', {
          duration: 1500,
          panelClass: ['snack-bar-error'],
        });

        return of(null);
      })
    );
  }
}
